var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Customizer } from '@fluentui/react/lib/Utilities';
import { CoherenceCustomizations } from '@cseo/styles';
import { initializeOBFeedback } from '@cseo/controls/lib/OfficeBrowerFeedback';
import { useLoginOnStartup } from '@employee-experience/common/lib/useLoginOnStartup';
import { ShellStyles } from '@employee-experience/shell/lib/Shell';
import { Nav } from '@employee-experience/shell/lib/Nav';
import { Main } from '@employee-experience/shell/lib/Main';
import { Footer } from '@employee-experience/shell/lib/Footer';
import { ShellWithStore } from './ShellWithStore';
import { navConfig } from './navConfig';
import { Routes } from './Routes';
import { LoadingIndicator } from '../src/Containers/CommonUtilities/LoadingIndicator';
import { NotificationProvider } from './Notification';
import { TopHeader } from './TopHeader';
initializeOBFeedback(1111, 'running-environment', '/ocv/OfficeBrowserFeedback.min.js', '/ocv/OfficeBrowserFeedback.min.css', '/ocv/intl/', 'https://office365.uservoice.com/');
function App() {
    useLoginOnStartup();
    var searchBoxStyle = {
        marginTop: '10px',
    };
    var footerStyle = {
        textAlign: 'center',
        height: '30px',
        background: 'rgb(229, 229, 229)'
    };
    return (React.createElement(Customizer, __assign({}, CoherenceCustomizations),
        React.createElement(LoadingIndicator, null),
        React.createElement(ShellStyles, null),
        React.createElement(BrowserRouter, null,
            React.createElement(NotificationProvider, null,
                React.createElement(TopHeader, null),
                React.createElement(Nav, { groups: navConfig }),
                React.createElement(Main, null,
                    React.createElement(Routes, null)),
                React.createElement(Footer, null,
                    React.createElement("div", { style: footerStyle },
                        "AutoSign UI v2.0 \u00A0",
                        React.createElement("a", { target: "_blank", href: "https://privacy.microsoft.com/en-US/data-privacy-notice" }, "Microsoft Privacy and Data Protection Notice")))))));
}
render(React.createElement(ShellWithStore, null,
    React.createElement(App, null)), document.getElementById('app'));
