var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { ConstrainMode, DetailsListLayoutMode } from '@fluentui/react/lib/DetailsList';
import { useState, useEffect } from 'react';
import { DetailsList } from '@fluentui/react/lib/DetailsList';
import { IconButton } from '@fluentui/react/lib/Button';
import { ComponentContext } from '@employee-experience/common/lib/Context/ComponentContext';
import { Panel, PanelType, Stack, Fabric, TooltipHost, SelectionMode, ScrollablePane, Breadcrumb, Sticky, StickyPositionType, Link, Pivot, PivotItem, DefaultButton, Dialog, DialogFooter, PrimaryButton, ContextualMenu, DialogType, TextField, FontIcon, CommandBarButton, SearchBox, } from '@fluentui/react';
import { toast } from 'react-toastify';
import '!style-loader!css-loader!react-toastify/dist/ReactToastify.css';
import * as Styled from '../../shared/layout';
import { useHistory } from 'react-router-dom';
import { httpGet, httpPut } from '../../shared/APICalls';
import { useBoolean } from '@uifabric/react-hooks';
import DownloadFiles from '../CommonUtilities/DownloadFiles';
import { stackItemHeadingStyles, stackItemRefreshStyles, stackItemSearchBoxStyles, statusIconCommnonStyles, statusIconStyles, tabLeftStackTokens, tabRighStackTokens } from './Styles';
import { ApproveRequestDetails } from './ApproveRequestDetails';
var appConfig = require('../../Config/appConfig.js').appConfig;
var EventEmitter = require('../CommonUtilities/EventEmitter.js').EventEmitter;
var EventType = require('../CommonUtilities/EventEmitter.js').EventType;
var baseUrl = appConfig.AppSettings.APIUrl;
var getTabId = function (itemKey) {
    return "ShapeColorPivot_".concat(itemKey);
};
var ViewMyApprovals = function (props) {
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var history = useHistory();
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var _b = useBoolean(false), isOpenForCreate = _b[0], _c = _b[1], openPanelForCreate = _c.setTrue, dismissPanelForCreate = _c.setFalse;
    var _d = useBoolean(false), isOpenToVeiewRequest = _d[0], _e = _d[1], openPanelToVeiewRequest = _e.setTrue, dismissPanelToVeiewRequest = _e.setFalse;
    var _f = useState(0), requestIdData = _f[0], setRequestIdData = _f[1];
    var _g = useState(''), requestorAliasData = _g[0], setRequestorAliasData = _g[1];
    var _h = useState(0), pendingRequestCount = _h[0], setPendingRequestCount = _h[1];
    var _j = useState(0), requestHistoryCount = _j[0], setRequestHistoryCount = _j[1];
    var _k = useState(sessionStorage.getItem('searchText') || ''), searchText = _k[0], setSearchText = _k[1];
    React.useEffect(function () {
        sessionStorage.setItem('searchText', searchText);
    }, [searchText]);
    var _l = useState(''), requestId = _l[0], setRequestId = _l[1];
    var _m = useState(''), rejectReason = _m[0], setRejectReason = _m[1];
    var _o = useState([]), columns = _o[0], setColumns = _o[1];
    var _p = useState([]), requestHistorycolumn = _p[0], setRequestHistorycolumn = _p[1];
    var lastStatusIcons = { marginTop: -7 };
    var lastStatusCancelIcons = { marginTop: -7, backgroundColor: '#ffebee' };
    var stackStyles = { root: { width: 500 } };
    var horizontalGapStackTokens = {
        childrenGap: 10,
        padding: 10,
    };
    var filterIcon = { iconName: 'Filter' };
    var labelStyles = {
        root: { marginTop: 10 },
    };
    var _q = useState([]), pendingRequests = _q[0], setPendingRequests = _q[1];
    var _r = useState([]), defaultPendingRequests = _r[0], setDefaultPendingRequests = _r[1];
    var _s = React.useState(true), hideMyApprovalsGrid = _s[0], setHideMyApprovalsGrid = _s[1];
    var _t = React.useState(true), hideMyApprovalsGridMessage = _t[0], setHideMyApprovalsGridMessage = _t[1];
    var _u = useState([]), requestsHistory = _u[0], setRequestsHistory = _u[1];
    var _v = useState([]), defaultRequestsHistory = _v[0], setDefaultRequestsHistory = _v[1];
    var itemsWithHref = [
        {
            text: 'Home',
            key: 'f1',
            onClick: function () {
                navigateToHomePage();
            },
        },
        {
            text: 'My Approvals',
            key: 'f5',
            isCurrentItem: true,
        },
    ];
    var _w = React.useState('PendingApprovals'), selectedKey = _w[0], setSelectedKey = _w[1];
    var refresh = { iconName: 'Refresh' };
    var handleLinkClick = function (item) {
        if (item) {
            setSelectedKey(item.props.itemKey);
        }
    };
    var dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
    };
    var modalPropsStyles = { main: { maxWidth: 500 } };
    var approveDialogContentProps = {
        type: DialogType.normal,
        title: 'Approve Request',
        subText: 'Do you want to Approve this request?',
    };
    var rejectDialogContentProps = {
        type: DialogType.normal,
        title: 'Reject Request',
        subText: 'Do you want to Reject this request?',
    };
    var resignDialogContentProps = {
        type: DialogType.normal,
        title: 'Re-Sign Request',
        subText: 'Do you want to Re-Sign this request?',
    };
    var _x = useBoolean(true), hideDialog = _x[0], toggleHideDialog = _x[1].toggle;
    var _y = useBoolean(true), hideApproveReqDialog = _y[0], toggleHideApproveReqDialog = _y[1].toggle;
    var _z = useBoolean(true), hideRejectReqDialog = _z[0], toggleHideRejectReqDialog = _z[1].toggle;
    var _0 = useBoolean(true), hideReSignReqDialog = _0[0], toggleHideReSignReqDialog = _0[1].toggle;
    var _1 = useBoolean(false), isDraggable = _1[0], toggleIsDraggable = _1[1].toggle;
    var modalProps = React.useMemo(function () { return ({
        isBlocking: true,
        styles: modalPropsStyles,
        dragOptions: isDraggable ? dragOptions : undefined,
    }); }, [isDraggable]);
    var _2 = React.useContext(ComponentContext), telemetryClient = _2.telemetryClient, authClient = _2.authClient;
    var _3 = React.useState(''), loggedInUser = _3[0], setLoggedInUser = _3[1];
    var _4 = useState(''), acccessToken = _4[0], setAcccessToken = _4[1];
    useEffect(function () {
        authClient.getUserId().then(function (res) {
            setLoggedInUser(res);
        });
        authClient.acquireToken(__CLIENT_API_SCOPES__).then(function (res) {
            setAcccessToken(res);
        });
    });
    var _itemsCommandBar = [
        // {
        //     key: 'newItem',
        //     text: "New Signing Request",
        //     cacheKey: 'myCacheKey',
        //     iconProps: { iconName: 'Add' },
        //     onClick: () => {
        //         openPanelForCreate();
        //         setRequestorAliasData(loggedInUser);
        //     },
        // },
        {
            key: 'Refresh',
            text: "Refresh",
            cacheKey: 'myCacheKey',
            iconProps: { iconName: 'Refresh' },
            onClick: function () {
                Refresh();
            },
        },
    ];
    useEffect(function () {
        toast.configure({
            position: 'top-center',
            autoClose: 5000,
            bodyClassName: 'toast-body',
            hideProgressBar: true,
            style: { marginTop: 50 },
        });
    }, []);
    var column = [
        {
            key: 'requestID',
            name: 'RequestID',
            ariaLabel: 'requestID',
            fieldName: 'requestID',
            isResizable: true,
            minWidth: 100,
            maxWidth: 100,
            onRender: function (item) { return (React.createElement(Link, { onClick: function () {
                    openPanelToVeiewRequest();
                    setRequestIdData(item.requestID);
                } }, item.requestID)); }
        },
        {
            key: 'requestor',
            name: 'Requestor',
            ariaLabel: 'requestor',
            fieldName: 'requestor',
            isResizable: true,
            minWidth: 120,
            maxWidth: 150,
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.requestor); },
        },
        {
            key: 'sponsor',
            name: 'Sponsor(s)',
            ariaLabel: 'sponsor',
            fieldName: 'sponsor',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.sponsor); },
        },
        {
            key: 'productName',
            name: 'Product Name',
            ariaLabel: 'productName',
            fieldName: 'productName',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.productName); },
        },
        {
            key: 'serialNumber',
            name: 'Serial Number',
            ariaLabel: 'serialNumber',
            fieldName: 'serialNumber',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.serialNumber); },
        },
        {
            key: 'signingType',
            name: 'Signing Type',
            ariaLabel: 'signingType',
            fieldName: 'signingType',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.signingType); },
        },
        {
            key: 'signingDetail',
            name: 'Signing Detail',
            ariaLabel: 'signingDetail',
            fieldName: 'signingDetail',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.signingDetail); },
        },
        // {
        //     key: 'firmwareVersion',
        //     name: 'Firmware Version',
        //     ariaLabel: 'firmwareVersion',
        //     fieldName: 'firmwareVersion',
        //     isResizable: true,
        //     minWidth: 100,
        //     maxWidth: 150,
        //     data: 'string',
        //     onRender: (item) => <span> {item.firmwareVersion}</span>,
        // },
        // {
        //     key: 'firmwareDescription',
        //     name: 'Firmware Description',
        //     ariaLabel: 'firmwareDescription',
        //     fieldName: 'firmwareDescription',
        //     isResizable: true,
        //     minWidth: 100,
        //     maxWidth: 150,
        //     data: 'string',
        //     onRender: (item) => <span> {item.firmwareDescription}</span>,
        // },
        // {
        //     key: 'requestDateTime',
        //     name: 'Request DateTime',
        //     ariaLabel: 'requestDateTime',
        //     fieldName: 'requestDateTime',
        //     isResizable: true,
        //     minWidth: 100,
        //     maxWidth: 150,
        //     data: 'string',
        //     onRender: (item) => <span> {item.requestDateTime}</span>,
        // },
        {
            key: 'statusCode',
            name: 'Status',
            ariaLabel: 'statusCode',
            fieldName: 'statusCode',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return (React.createElement("div", null,
                React.createElement("span", { hidden: item.statusCode != 'Processing' && item.statusCode != 'Preparing' && item.statusCode != 'Initialized' },
                    React.createElement(FontIcon, { "aria-label": "Processing", iconName: "ProgressRingDots", className: statusIconCommnonStyles })),
                React.createElement("span", { hidden: item.statusCode != 'Succeeded' && item.statusCode != 'Approved' && item.statusCode != 'SponsorApprovedProcessing' },
                    React.createElement(FontIcon, { "aria-label": "Succeeded", iconName: "CompletedSolid", className: statusIconStyles.completedIcon })),
                React.createElement("span", { hidden: item.statusCode != 'Failed' },
                    React.createElement(FontIcon, { "aria-label": "Failed", iconName: "ErrorBadge", className: statusIconStyles.failedIcon })),
                React.createElement("span", { hidden: item.statusCode != 'Cancelled' && item.statusCode != 'MarkedApproverRejected' && item.statusCode != 'ApproverRejected' && item.statusCode != 'SponsorRejected' },
                    React.createElement(FontIcon, { "aria-label": "Cancelled", iconName: "Cancel", className: statusIconStyles.failedIcon })),
                React.createElement("span", { hidden: item.statusCode != 'INDELAY' },
                    React.createElement(FontIcon, { "aria-label": "InDelay", iconName: "CirclePause", className: statusIconStyles.failedIcon })),
                React.createElement("span", { hidden: item.statusCode != 'Completed' && item.statusCode != 'Fulfilled' },
                    React.createElement(FontIcon, { "aria-label": "Completed", iconName: "Completed", className: statusIconStyles.completedIcon })),
                React.createElement("span", { hidden: item.statusCode != 'New' },
                    React.createElement(FontIcon, { "aria-label": "New", iconName: "NewAnalyticsQuery", className: statusIconCommnonStyles })),
                React.createElement("span", { hidden: item.statusCode != 'AwaitingApproval' && item.statusCode != 'AwaitingSponsorApproval' && item.statusCode != 'AwaitingSignatureUpload' },
                    React.createElement(FontIcon, { "aria-label": "AwaitingApproval", iconName: "NewAnalyticsQuery", className: statusIconCommnonStyles })),
                React.createElement("span", null, item.statusCode))); }
        },
        {
            key: 'action',
            name: 'Actions',
            ariaLabel: 'Actions',
            fieldName: 'action',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            onRender: function (item) { return (React.createElement("div", null,
                React.createElement(IconButton, { iconProps: { iconName: 'Accept' }, onClick: function () {
                        toggleHideApproveReqDialog();
                        setRequestId(item.requestID);
                    }, title: "Approve Request" }),
                React.createElement(IconButton, { onClick: function () {
                        toggleHideRejectReqDialog();
                        setRequestId(item.requestID);
                    }, iconProps: { iconName: 'Cancel' }, title: 'Reject Request' }),
                React.createElement(IconButton, { disabled: item.statusCode != 'Failed', onClick: function () {
                        RequestAction(item.requestID, 'Approve');
                    }, iconProps: { iconName: 'DocumentApproval' }, title: 'Re-Sign Request' }),
                React.createElement(DownloadFiles, { requestId: item.requestID, blobPrefix: "FilesToSign/", title: "Download Input Files", fileTypeId: "FILE_TO_SIGN" }))); },
        },
    ];
    var requestHistorycolumn = [
        {
            key: 'requestID',
            name: 'RequestID',
            ariaLabel: 'requestID',
            fieldName: 'requestID',
            isResizable: true,
            minWidth: 80,
            maxWidth: 80,
            onRender: function (item) { return (React.createElement(Link, { onClick: function () {
                    openPanelToVeiewRequest();
                    setRequestIdData(item.requestID);
                } }, item.requestID)); }
        },
        {
            key: 'requestor',
            name: 'Requestor',
            ariaLabel: 'requestor',
            fieldName: 'requestor',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.requestor); },
        },
        {
            key: 'sponsor',
            name: 'Sponsor',
            ariaLabel: 'sponsor',
            fieldName: 'sponsor',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.sponsor); },
        },
        {
            key: 'approver',
            name: 'Approver',
            ariaLabel: 'approver',
            fieldName: 'approver',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.approver); },
        },
        {
            key: 'productName',
            name: 'Product Name',
            ariaLabel: 'productName',
            fieldName: 'productName',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.productName); },
        },
        {
            key: 'serialNumber',
            name: 'Serial Number',
            ariaLabel: 'serialNumber',
            fieldName: 'serialNumber',
            isResizable: true,
            minWidth: 100,
            maxWidth: 120,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.serialNumber); },
        },
        {
            key: 'signingType',
            name: 'Signing Type',
            ariaLabel: 'signingType',
            fieldName: 'signingType',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.signingType); },
        },
        {
            key: 'signingDetail',
            name: 'Signing Detail',
            ariaLabel: 'signingDetail',
            fieldName: 'signingDetail',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.signingDetail); },
        },
        {
            key: 'firmwareVersion',
            name: 'Firmware Version',
            ariaLabel: 'firmwareVersion',
            fieldName: 'firmwareVersion',
            isResizable: true,
            minWidth: 120,
            maxWidth: 120,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.firmwareVersion); },
        },
        {
            key: 'firmwareDescription',
            name: 'Firmware Description',
            ariaLabel: 'firmwareDescription',
            fieldName: 'firmwareDescription',
            isResizable: true,
            minWidth: 150,
            maxWidth: 200,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.firmwareDescription); },
        },
        // {
        //     key: 'requestDateTime',
        //     name: 'Request DateTime',
        //     ariaLabel: 'requestDateTime',
        //     fieldName: 'requestDateTime',
        //     isResizable: true,
        //     minWidth: 100,
        //     maxWidth: 150,
        //     data: 'string',
        //     onRender: (item) => <span> {item.requestDateTime}</span>,
        // },
        {
            key: 'statusCode',
            name: 'Status',
            ariaLabel: 'statusCode',
            fieldName: 'statusCode',
            isResizable: true,
            minWidth: 100,
            maxWidth: 120,
            data: 'string',
            onRender: function (item) { return (React.createElement("div", null,
                React.createElement("span", { hidden: item.statusCode != 'Processing' && item.statusCode != 'Preparing' && item.statusCode != 'Initialized' },
                    React.createElement(FontIcon, { "aria-label": "Processing", iconName: "ProgressRingDots", className: statusIconCommnonStyles })),
                React.createElement("span", { hidden: item.statusCode != 'Succeeded' && item.statusCode != 'Approved' && item.statusCode != 'SponsorApprovedProcessing' },
                    React.createElement(FontIcon, { "aria-label": "Succeeded", iconName: "CompletedSolid", className: statusIconStyles.completedIcon })),
                React.createElement("span", { hidden: item.statusCode != 'Failed' },
                    React.createElement(FontIcon, { "aria-label": "Failed", iconName: "ErrorBadge", className: statusIconStyles.failedIcon })),
                React.createElement("span", { hidden: item.statusCode != 'Cancelled' && item.statusCode != 'ApproverRejected' && item.statusCode != 'SponsorRejected' },
                    React.createElement(FontIcon, { "aria-label": "Cancelled", iconName: "Cancel", className: statusIconStyles.failedIcon })),
                React.createElement("span", { hidden: item.statusCode != 'INDELAY' },
                    React.createElement(FontIcon, { "aria-label": "InDelay", iconName: "CirclePause", className: statusIconStyles.failedIcon })),
                React.createElement("span", { hidden: item.statusCode != 'Completed' && item.statusCode != 'Fulfilled' },
                    React.createElement(FontIcon, { "aria-label": "Completed", iconName: "Completed", className: statusIconStyles.completedIcon })),
                React.createElement("span", { hidden: item.statusCode != 'New' },
                    React.createElement(FontIcon, { "aria-label": "New", iconName: "NewAnalyticsQuery", className: statusIconCommnonStyles })),
                React.createElement("span", { hidden: item.statusCode != 'AwaitingApproval' },
                    React.createElement(FontIcon, { "aria-label": "AwaitingApproval", iconName: "NewAnalyticsQuery", className: statusIconCommnonStyles })),
                React.createElement("span", null, item.statusCode))); }
        },
        {
            key: 'action',
            name: 'Actions',
            ariaLabel: 'Actions',
            fieldName: 'action',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            onRender: function (item) { return (React.createElement("div", null,
                React.createElement(DownloadFiles, { requestId: item.requestID, blobPrefix: "SignedFiles/", disabled: item.statusCode != 'Completed' && item.statusCode != 'Fulfilled', title: "Download Signed Files", fileTypeId: "SIGNED_FILE" }))); },
        },
    ];
    var navigateToHomePage = function () {
        history.push("/dashboard");
    };
    var navigateToApproveRequestDetailsPage = function (requestID) {
        history.push("/approve-request-details/".concat(requestID));
    };
    var SearchResults = function (searchId) { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            if (searchId != "" && loggedInUser != "" && acccessToken != "") {
                url = "".concat(baseUrl, "/signing-approvals/GetSigningRequestDetails?requestId=").concat(searchId);
                httpGet(_httpClient, acccessToken, url, function (response) {
                    if (response.data[0].statusCode == 'AwaitingApproval' || response.data[0].statusCode == 'AwaitingSponsorApproval'
                        || response.data[0].statusCode == 'Failed') {
                        setColumns(column);
                        setPendingRequests(response.data);
                        setDefaultPendingRequests(response.data);
                    }
                    else {
                        toast.error("RequestID: " + searchId + " isn't waiting for your approval. If you looking for this request, please use Home page top search to find it");
                    }
                }, function (error) {
                    toast.error("RequestID: ".concat(searchId, " not found"));
                });
            }
            return [2 /*return*/];
        });
    }); };
    var SearchResultsGetReqHistory = function (searchId) { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            if (searchId != "" && loggedInUser != "" && acccessToken != "") {
                url = "".concat(baseUrl, "/signing-approvals/GetSigningRequestDetails?requestId=").concat(searchId);
                httpGet(_httpClient, acccessToken, url, function (response) {
                    setColumns(requestHistorycolumn);
                    setRequestsHistory(response.data);
                    setDefaultRequestsHistory(response.data);
                }, function (error) {
                    toast.error("RequestID: ".concat(searchId, " not found"));
                    telemetryClient.trackException({
                        exception: error.data.Message,
                        properties: { 'Component Name': 'MyApprovals', 'Method Name': 'SearchResultsGetReqHistory', },
                    });
                });
            }
            return [2 /*return*/];
        });
    }); };
    var SigningRequestsWaitingForMyApproval = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            if (loggedInUser != "" && acccessToken != "") {
                url = "".concat(baseUrl, "/signing-approvals/GetAllSigningRequestsWaitingForMyApproval");
                httpGet(_httpClient, acccessToken, url, function (response) {
                    setColumns(column);
                    setPendingRequests(response.data);
                    setDefaultPendingRequests(response.data);
                    setHideMyApprovalsGrid(false);
                    setHideMyApprovalsGridMessage(true);
                    setPendingRequestCount(response.data.length);
                }, function (error) {
                    toast.error(error.data.Message);
                    telemetryClient.trackException({
                        exception: error.data.Message,
                        properties: { 'Component Name': 'MyApprovals', 'Method Name': 'SigningRequestsWaitingForMyApproval', },
                    });
                });
            }
            return [2 /*return*/];
        });
    }); };
    var GetAllApprovalHistoryByRequestor = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            if (loggedInUser != "" && acccessToken != "") {
                url = "".concat(baseUrl, "/signing-approvals/GetAllApprovalHistoryByRequestor");
                httpGet(_httpClient, acccessToken, url, function (response) {
                    setRequestHistorycolumn(requestHistorycolumn);
                    setRequestsHistory(response.data);
                    setDefaultRequestsHistory(response.data);
                    setRequestHistoryCount(response.data.length);
                }, function (error) {
                    toast.error(error.data.Message);
                    telemetryClient.trackException({
                        exception: error.data.Message,
                        properties: { 'Component Name': 'MyApprovals', 'Method Name': 'GetAllApprovalHistoryByRequestor', },
                    });
                });
            }
            return [2 /*return*/];
        });
    }); };
    var RequestAction = function (requestId, action) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (action == 'Approve') {
                approveSigningRequest(requestId, action);
            }
            else if (action == 'Reject') {
                rejectSigningRequest(requestId, action);
            }
            return [2 /*return*/];
        });
    }); };
    var approveSigningRequest = function (requestId, action) { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            if (requestId != "" && action != "" && acccessToken != "") {
                url = "".concat(baseUrl, "/signing-approvals/RequestAction?requestId=").concat(requestId, "&&approverAction=").concat(action);
                httpPut(_httpClient, acccessToken, url, function (response) {
                    toast.success("RequestID: " + requestId + " has been Approved successfully and it will be proceed for signing");
                    Refresh();
                }, function (error) {
                    if (error.data != '') {
                        var message = error.data.Message != '' ? error.data.Message : error.data;
                        toast.error("Error while approving RequestId: " + requestId + " and error is " + message);
                    }
                    else if (error.statusText != '') {
                        toast.error("Error while approving RequestId: " + requestId + " and error is " + error.statusText);
                    }
                    telemetryClient.trackException({
                        exception: error.data.Message,
                        properties: { 'Component Name': 'MyApprovals', 'Method Name': 'approveSigningRequest', },
                    });
                }, JSON.stringify({}));
            }
            return [2 /*return*/];
        });
    }); };
    var rejectSigningRequest = function (requestId, action) { return __awaiter(void 0, void 0, void 0, function () {
        var errorObj, url;
        return __generator(this, function (_a) {
            errorObj = validateRejectRequest();
            if (errorObj.isPassed) {
                if (requestId != "" && action != "" && acccessToken != "") {
                    url = "".concat(baseUrl, "/signing-approvals/RequestAction?requestId=").concat(requestId, "&&approverAction=").concat(action, "&&rejectReason=").concat(rejectReason);
                    httpPut(_httpClient, acccessToken, url, function (response) {
                        toast.success("RequestID: " + requestId + " has been Rejected.");
                        Refresh();
                    }, function (error) {
                        toast.error("Error while rejecting RequestId: " + requestId + " and error is :" + error.data);
                        telemetryClient.trackException({
                            exception: error.data.Message,
                            properties: { 'Component Name': 'MyApprovals', 'Method Name': 'rejectSigningRequest', },
                        });
                    }, JSON.stringify({}));
                }
            }
            else {
                toast.error(errorObj.errorMessage);
            }
            return [2 /*return*/];
        });
    }); };
    var validateRejectRequest = function () {
        var errorObj = {
            isPassed: false,
            errorMessage: ''
        };
        if (rejectReason == "" || rejectReason == null) {
            errorObj.errorMessage = "Please provide reject reason";
            errorObj.isPassed = false;
            return errorObj;
        }
        // if(requestDetails[0].statusCode != 'AwaitingSponsorApproval' && requestDetails[0].statusCode != 'AwaitingApproval'){
        //     errorObj.errorMessage = "Requst is not in AwaitingSponsorApproval or AwaitingApproval";
        //     errorObj.isPassed = false;
        //     return errorObj;
        // }
        errorObj.isPassed = true;
        return errorObj;
    };
    useEffect(function () {
        SigningRequestsWaitingForMyApproval();
        GetAllApprovalHistoryByRequestor();
    }, [loggedInUser, acccessToken]);
    var Refresh = function () {
        SigningRequestsWaitingForMyApproval();
        GetAllApprovalHistoryByRequestor();
    };
    var onSubmit = function (ev) {
        ev.preventDefault();
    };
    var createSigningRequestOnPanelSubmit = function (item) {
        dismissPanelForCreate();
    };
    var veiewRequestOnPanelSubmit = function (item) {
        dismissPanelToVeiewRequest();
    };
    var onRenderDetailsHeader = function (props, defaultRender) {
        if (!props) {
            return null;
        }
        var onRenderColumnHeaderTooltip = function (tooltipHostProps) { return (React.createElement(TooltipHost, __assign({}, tooltipHostProps))); };
        return (React.createElement(Sticky, { stickyPosition: StickyPositionType.Header, isScrollSynced: true }, defaultRender(__assign(__assign({}, props), { onRenderColumnHeaderTooltip: onRenderColumnHeaderTooltip }))));
    };
    var onRejectReasonUpdated = function (ev, rejectReason) {
        setRejectReason(rejectReason);
    };
    var onSearchHandler = function (event) {
        if (event && event.target) {
            var queryText = event.target.value;
            setSearchText(queryText);
            if (queryText) {
                var filteredData = filterData(queryText);
                setDefaultPendingRequests(filteredData);
            }
            else {
                setDefaultPendingRequests(pendingRequests);
            }
        }
        else {
            setSearchText('');
            setDefaultPendingRequests(pendingRequests);
        }
    };
    var filterData = function (queryText) {
        return pendingRequests.filter(function (_items) {
            return (_items.requestID && _items.requestID.toString().toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.requestor && _items.requestor.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.sponsor && _items.sponsor.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.approver && _items.approver.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.productName && _items.productName.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.signingDetail && _items.signingDetail.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.signingType && _items.signingType.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.statusCode && _items.statusCode.toLowerCase().includes(queryText.trim().toLowerCase()));
        });
    };
    var onSearchHandlerGetRequestsHistory = function (event) {
        if (event && event.target) {
            var queryText = event.target.value;
            setSearchText(queryText);
            if (queryText) {
                var filteredData = filterRequestsHistoryData(queryText);
                setDefaultRequestsHistory(filteredData);
            }
            else {
                setDefaultRequestsHistory(requestsHistory);
            }
        }
        else {
            setSearchText('');
            setDefaultRequestsHistory(requestsHistory);
        }
    };
    var filterRequestsHistoryData = function (queryText) {
        return requestsHistory.filter(function (_items) {
            return (_items.requestID && _items.requestID.toString().toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.requestor && _items.requestor.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.sponsor && _items.sponsor.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.approver && _items.approver.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.productName && _items.productName.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.signingDetail && _items.signingDetail.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.signingType && _items.signingType.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.statusCode && _items.statusCode.toLowerCase().includes(queryText.trim().toLowerCase()));
        });
    };
    function dateToISOLikeButLocal(date) {
        var offsetMs = date.getTimezoneOffset() * 60 * 1000;
        var msLocal = date.getTime() - offsetMs;
        var dateLocal = new Date(msLocal);
        var iso = dateLocal.toISOString();
        var isoLocal = iso.slice(0, 10);
        return isoLocal;
    }
    var onClickGetReqHistoryDataHander = function (event) {
        var metricData = {
            name: 'Top Search',
        };
        var additionalProperties = { 'Component Name': 'MyApprovals' };
        if (event.target.value.length > 0) {
            SearchResultsGetReqHistory(event.target.value);
            telemetryClient.trackMetric(metricData, additionalProperties);
        }
        else {
            toast.error("Please enter valid search criteria in search box");
        }
    };
    var onClickGetDataHandler = function (event) {
        var metricData = {
            name: 'Top Search',
        };
        var additionalProperties = { 'Component Name': 'MyApprovals' };
        if (event.target.value.length > 0) {
            SearchResults(event.target.value);
            telemetryClient.trackMetric(metricData, additionalProperties);
        }
        else {
            toast.error("Please enter valid search criteria in search box");
        }
    };
    React.useEffect(function () {
        EventEmitter.subscribe(EventType.onClick, onClickGetDataHandler);
        return function cleanup() {
            EventEmitter.unsubscribe(EventType.onClick, onClickGetDataHandler);
        };
    });
    React.useEffect(function () {
        EventEmitter.subscribe(EventType.onClick, onClickGetReqHistoryDataHander);
        return function cleanup() {
            EventEmitter.unsubscribe(EventType.onClick, onClickGetReqHistoryDataHander);
        };
    });
    var getApprovalDetailsComponent = function (key) {
        if (key === 'PendingApprovals') {
            if (pendingRequestCount > 0) {
                return React.createElement(ScrollablePane, { style: { height: '72.5vh', position: 'relative' } },
                    React.createElement(DetailsList, { compact: true, columns: columns, items: defaultPendingRequests, layoutMode: DetailsListLayoutMode.justified, constrainMode: ConstrainMode.unconstrained, onRenderDetailsHeader: onRenderDetailsHeader, ariaLabel: "Value", ariaLabelForSelectAllCheckbox: "Toggle selection for all items", ariaLabelForSelectionColumn: "Toggle selection", checkButtonAriaLabel: "Row checkbox", selectionMode: SelectionMode.none }));
            }
            else {
                return React.createElement("div", null, "You don't have any requests waiting for approval");
            }
        }
        if (key === 'ApprovalHistory') {
            if (requestHistoryCount > 0) {
                return React.createElement(ScrollablePane, { style: { height: '73vh', position: 'relative' } },
                    React.createElement(DetailsList, { compact: true, columns: requestHistorycolumn, items: defaultRequestsHistory, layoutMode: DetailsListLayoutMode.justified, constrainMode: ConstrainMode.unconstrained, onRenderDetailsHeader: onRenderDetailsHeader, ariaLabel: "Value", ariaLabelForSelectAllCheckbox: "Toggle selection for all items", ariaLabelForSelectionColumn: "Toggle selection", checkButtonAriaLabel: "Row checkbox", selectionMode: SelectionMode.none }));
            }
            else {
                return React.createElement("div", null, "You haven't approved any requests");
            }
        }
    };
    return (React.createElement(Styled.listContainer, null,
        React.createElement(Fabric, null,
            React.createElement(Breadcrumb, { items: itemsWithHref, maxDisplayedItems: 3, ariaLabel: "My Approvals", overflowAriaLabel: "More links", style: { backgroundColor: '#FFFFFF' } }),
            React.createElement(Stack, null,
                React.createElement(Stack, { horizontal: true, horizontalAlign: "space-between" },
                    React.createElement(Stack, { horizontal: true, styles: stackStyles, tokens: tabLeftStackTokens },
                        React.createElement(Stack.Item, { grow: 1, styles: stackItemHeadingStyles },
                            React.createElement(Pivot, { "aria-label": "Separately Rendered Content Pivot Example", selectedKey: selectedKey, 
                                // eslint-disable-next-line react/jsx-no-bind
                                onLinkClick: handleLinkClick, headersOnly: true, getTabId: getTabId },
                                React.createElement(PivotItem, { headerText: "Pending Approvals", itemCount: pendingRequestCount, itemIcon: "WaitlistConfirm", itemKey: 'PendingApprovals' }),
                                React.createElement(PivotItem, { headerText: "Approval History", itemCount: requestHistoryCount, itemIcon: "History", itemKey: 'ApprovalHistory' })))),
                    React.createElement(Stack, { horizontal: true, styles: stackStyles, tokens: tabRighStackTokens },
                        React.createElement(Stack.Item, { grow: 1, styles: stackItemRefreshStyles },
                            React.createElement(SearchBox, { placeholder: "Filter", iconProps: filterIcon, styles: stackItemSearchBoxStyles, onChange: function (e) {
                                    onSearchHandler(e);
                                    onSearchHandlerGetRequestsHistory(e);
                                } }),
                            React.createElement(CommandBarButton, { iconProps: refresh, onClick: Refresh, style: { backgroundColor: 'transparent' } }, "Refresh")))),
                React.createElement("div", { "aria-labelledby": getTabId(selectedKey), role: "tabpanel", style: {
                        float: 'left',
                        //width: 100,
                        //height: selectedKey === 'squareRed' ? 100 : 200,
                        //background: selectedKey === 'rectangleGreen' ? 'green' : 'red',
                    } }, getApprovalDetailsComponent(selectedKey))),
            React.createElement(Panel, { headerText: "Signing Request Details", isOpen: isOpenToVeiewRequest, onDismiss: dismissPanelToVeiewRequest, isLightDismiss: true, type: PanelType.medium, closeButtonAriaLabel: "Close" },
                React.createElement(ApproveRequestDetails, { requestIdData: requestIdData, onPanelSubmit: veiewRequestOnPanelSubmit })),
            React.createElement(Dialog, { hidden: hideApproveReqDialog, onDismiss: toggleHideApproveReqDialog, dialogContentProps: approveDialogContentProps, modalProps: modalProps, minWidth: 525 },
                React.createElement(DialogFooter, null,
                    React.createElement(PrimaryButton, { onClick: function () {
                            toggleHideApproveReqDialog();
                            RequestAction(requestId, "Approve");
                        }, text: "Approve" }),
                    React.createElement(DefaultButton, { onClick: toggleHideApproveReqDialog, text: "Cancel" }))),
            React.createElement(Dialog, { hidden: hideRejectReqDialog, onDismiss: toggleHideRejectReqDialog, dialogContentProps: rejectDialogContentProps, modalProps: modalProps, minWidth: 525 },
                React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
                    React.createElement(TextField, { label: "Reason for rejection: ", required: true, multiline: true, rows: (4), style: { width: 450 }, onChange: function (e, text) { return onRejectReasonUpdated(e, text); } })),
                React.createElement(DialogFooter, null,
                    React.createElement(PrimaryButton, { onClick: function () {
                            toggleHideRejectReqDialog();
                            RequestAction(requestId, "Reject");
                        }, text: "Reject" }),
                    React.createElement(DefaultButton, { onClick: toggleHideRejectReqDialog, text: "Cancel" }))))));
};
export default ViewMyApprovals;
