var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Header } from "@employee-experience/shell";
import React from "react";
import NotificationContext from "./Notification";
import { useHeaderConfig } from "./useHeaderConfig";
export function TopHeader() {
    var _a = React.useContext(NotificationContext), items = _a.items, headerBody = _a.headerBody, updateItem = _a.updateItem;
    var headerConfig = useHeaderConfig({ items: items, headerBody: headerBody, updateItem: updateItem });
    return (React.createElement(Header, __assign({}, headerConfig)));
}
