import { DefaultButton, IconButton, Label, Stack } from "@fluentui/react";
import React from "react";
import { useRef } from "react";
var errorMessageStyles = {
    root: { color: 'rgb(164, 38, 44)', fontWeight: 400, fontFamily: 'Segoe UI', fontSize: '12px' }
};
export function FileUploader(props) {
    var hiddenFileInput = useRef(null);
    var handleClick = function (event) {
        var _a, _b;
        if ((_a = hiddenFileInput.current) === null || _a === void 0 ? void 0 : _a.value)
            hiddenFileInput.current.value = '';
        (_b = hiddenFileInput.current) === null || _b === void 0 ? void 0 : _b.click();
    };
    var handleChange = function (event) {
        if (event.target.files && event.target.files.length > 0) {
            for (var i = 0; i < event.target.files.length; i++)
                props.onFileAdd(event.target.files[i]);
        }
    };
    var handleRemoveFile = function (file) {
        props.onFileRemove(file);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DefaultButton, { iconProps: { iconName: 'Add' }, onClick: handleClick, ariaLabel: 'FileUpload' }, "Choose File"),
        props.files.length > 0 ?
            (React.createElement(Stack, { horizontal: true, wrap: true }, props.files.map(function (file) { return (React.createElement(Stack, { horizontal: true },
                React.createElement(Label, { key: file.name }, file.name),
                React.createElement(IconButton, { iconProps: { iconName: 'Cancel' }, onClick: function () { return handleRemoveFile(file); } }))); })))
            : React.createElement("div", null, "No files chosen"),
        React.createElement(Label, { styles: errorMessageStyles }, props.validationMessage),
        React.createElement("input", { type: "file", ref: hiddenFileInput, onChange: handleChange, multiple: true, style: { display: 'none' } })));
}
