var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ComponentContext } from "@employee-experience/common";
import { DefaultButton, IconButton, Label, PrimaryButton, Stack, TextField, TooltipHost } from "@fluentui/react";
import React from "react";
import { useContext, useEffect, useReducer, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import NotificationContext from "../../Notification";
import { AutoSignBlobStorageService } from "../../Services/BlobServices/AutoSignBlobStorageService";
import { getSigningTypeByName, prepareRequest, prepareSasRequest, submitRequest } from "../../Services/SigningSubmissionApi";
import { Loading } from "../CommonUtilities/Loading";
import { stackItemRequestContentStyles, stackItemRequestHeaderStyles } from "../ManageSigning/Styles";
import { displaySignFormReducer, Initial_Sign_Request_Display } from "./displaySignFormReducer";
import { FileUploader } from "./FileUploader";
import { ProductDropdown } from "./ProductDropdown";
import { Initial_Sign_Request, signFormReducer } from "./signFormReducer";
import { Initia_Sign_Validations, signFormValidationReducer } from "./signFormValidationReducer";
import { SigningTypeDropdown } from "./SigningTypeDropdown";
import { UserPicker } from "./UserPicker";
import { artifactVersionValidator, businessJustificationValidator, filesToSignValidator, isNonFTE, isSerialNumberRequired, isSmokeTestResultRequired, serialNumberValidator, signingTypeValidator, smokeTestResultValidator, sponsorsValidator } from "./Validations";
import { appConfig } from "../../Config/appConfig";
var textFieldStyles = { fieldGroup: { width: 300 } };
var dropdownStyles = {
    root: { width: 300 },
};
function mapSigningSubmissionData(formData) {
    var _a;
    var submissionData = {
        requestId: null,
        sponsors: (_a = formData.sponsors) === null || _a === void 0 ? void 0 : _a.map(function (s) { return { domainName: s }; }),
        signingType: formData.signingType,
        serialNumber: formData.serialNumber || '',
        businessJustification: formData.businessJustification || '',
        signingArtifactInfo: { version: formData.artifactVersion, description: formData.artifactDescription },
        notificationInfo: formData.mailCC && { emailListForCc: formData.mailCC },
        clientApplicationInfo: { applicationName: 'AutoSignWebsite', applicationVersion: appConfig.AppSettings.AppVersion },
        parentRequestId: "",
        requestStatus: "",
        fileToSignMetadataList: formData.filesToSign.map(function (f, i) {
            var _a;
            return {
                fileToSignName: f.name,
                fileToSignPath: '',
                fileCreationDate: null,
                fileLastModifiedDate: null,
                fileOwner: '',
                machineName: '',
                smokeTestFileName: (_a = formData.smokeTestResult[i]) === null || _a === void 0 ? void 0 : _a.name,
                smokeTestFilePath: '',
                signedFileName: '',
                compressedFileName: null,
                blobSettings: {}
            };
        }),
        sasToken: "",
        infoMessage: "",
        blobSettings: null,
        environmentMetadata: null
    };
    return submissionData;
}
export function SignRequestForm() {
    var _this = this;
    var _a = useReducer(displaySignFormReducer, Initial_Sign_Request_Display), displayState = _a[0], displayDispatch = _a[1];
    var _b = useReducer(signFormReducer, Initial_Sign_Request), state = _b[0], dispatch = _b[1];
    var _c = useState(''), loggedInUser = _c[0], setLoggedInUser = _c[1];
    var _d = useState(false), isSponsorRequired = _d[0], setIsSponsorRequired = _d[1];
    var _e = useState(false), isSubmitting = _e[0], setIsSubmitting = _e[1];
    var _f = useState(), signingTypeData = _f[0], setSigningTypeData = _f[1];
    var _g = useContext(ComponentContext), telemetryClient = _g.telemetryClient, authClient = _g.authClient;
    var history = useHistory();
    var _h = useReducer(signFormValidationReducer, Initia_Sign_Validations), validationState = _h[0], dispatchValidation = _h[1];
    var _j = useContext(NotificationContext), success = _j.success, error = _j.error, inProgress = _j.inProgress;
    var navigateToMyRequestsPage = function () {
        history.push("/view-my-requests");
    };
    useEffect(function () {
        authClient.getUserId().then(function (res) {
            setLoggedInUser(res);
            if (isNonFTE(res))
                setIsSponsorRequired(true);
        });
    }, []);
    var onProductChange = function (item) {
        if (item) {
            dispatchValidation({ type: 'onReset' });
            displayDispatch({
                type: "onProductSelect",
                payload: { name: 'product', value: item === null || item === void 0 ? void 0 : item.text }
            });
            dispatch({
                type: "onProductChange",
                payload: { name: 'productID', value: item === null || item === void 0 ? void 0 : item.key }
            });
            dispatch({
                type: "onProductChange",
                payload: { name: 'productName', value: item === null || item === void 0 ? void 0 : item.text }
            });
        }
    };
    var onSigningTypeChange = function (item) {
        if (item) {
            dispatchValidation({ type: 'onReset' });
            setSigningTypeData(item.data);
            displayDispatch({
                type: "onSigningTypeSelect",
                payload: { name: 'signingType', value: item === null || item === void 0 ? void 0 : item.text }
            });
            dispatch({
                type: "onValueChange",
                payload: { name: 'signingType', value: item === null || item === void 0 ? void 0 : item.key }
            });
        }
    };
    var onSigningTypeCodeChange1 = function (value) {
        dispatchValidation({ type: 'onReset' });
        setSigningTypeData(undefined);
        displayDispatch({
            type: "onProductSelect",
            payload: { name: 'product', value: 'Future Product' }
        });
        dispatch({
            type: "onProductChange",
            payload: { name: 'signingType', value: value }
        });
        //onValueChange('signingType',value)
    };
    var onSigningTypeCodeChange = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatchValidation({ type: 'onReset' });
            setSigningTypeData(undefined);
            setIsSubmitting(true);
            getSigningTypeByName(authClient, state.signingType).then(function (res) {
                setSigningTypeData(res.data);
                dispatch({
                    type: "onValueChange",
                    payload: { name: 'signingType', value: state.signingType }
                });
                displayDispatch({
                    type: "onSigningTypeCodeValid",
                    payload: { name: 'signingType', value: res.data.detail }
                });
                setIsSubmitting(false);
            }).catch(function (e) {
                dispatchValidation({ type: 'onValidationError', payload: { name: 'signingTypeError', value: 'Failed to get signing type' } });
                displayDispatch({
                    type: "onProductSelect",
                    payload: { name: 'product', value: 'Future Product' }
                });
                setIsSubmitting(false);
            });
            return [2 /*return*/];
        });
    }); };
    var onFileToSignAdd = function (file) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch({
                type: "onFileToSignAdd",
                payload: { name: 'filesToSign', value: file }
            });
            return [2 /*return*/];
        });
    }); };
    var onFileToSignRemove = function (file) {
        dispatch({
            type: "onFileToSignRemove",
            payload: { name: 'filesToSign', value: file }
        });
    };
    var onSmokeTestResultAdd = function (file) {
        dispatch({
            type: "onSmokeTestResultAdd",
            payload: { name: 'smokeTestResult', value: file }
        });
    };
    var onSmokeTestResultRemove = function (file) {
        dispatch({
            type: "onSmokeTestResultRemove",
            payload: { name: 'smokeTestResult', value: file }
        });
    };
    var onUserPickerChange = function (name, mails) {
        dispatch({
            type: 'onValueChange',
            payload: { name: name, value: mails }
        });
    };
    var onValueChange = function (name, value) {
        dispatch({
            type: 'onValueChange',
            payload: { name: name, value: value }
        });
    };
    var signFormValidator = function () {
        var isFormValid = true;
        var sponsorsError = sponsorsValidator(isSponsorRequired, state.sponsors);
        if (sponsorsError) {
            dispatchValidation({
                type: 'onValidationError',
                payload: {
                    name: 'sponsorsError',
                    value: sponsorsError
                }
            });
            isFormValid = false;
        }
        var signingTypeError = signingTypeValidator(state.signingType);
        if (signingTypeError) {
            dispatchValidation({
                type: 'onValidationError',
                payload: {
                    name: 'signingTypeError',
                    value: signingTypeError
                }
            });
            isFormValid = false;
        }
        var serialNumberError = serialNumberValidator(state.serialNumber, signingTypeData === null || signingTypeData === void 0 ? void 0 : signingTypeData.template, state.signingType);
        if (serialNumberError) {
            dispatchValidation({
                type: 'onValidationError',
                payload: {
                    name: 'serialNumberError',
                    value: serialNumberError
                }
            });
            isFormValid = false;
        }
        var businessJustificationError = businessJustificationValidator(state.businessJustification);
        if (businessJustificationError) {
            dispatchValidation({
                type: 'onValidationError',
                payload: {
                    name: 'businessJustificationError',
                    value: businessJustificationError
                }
            });
            isFormValid = false;
        }
        var artifactVersionError = artifactVersionValidator(state.artifactVersion, state.signingType);
        if (artifactVersionError) {
            dispatchValidation({
                type: 'onValidationError',
                payload: {
                    name: 'artifactVersionError',
                    value: artifactVersionError
                }
            });
            isFormValid = false;
        }
        var filesToSignError = filesToSignValidator(signingTypeData === null || signingTypeData === void 0 ? void 0 : signingTypeData.inputFileRequired, state.filesToSign);
        if (filesToSignError) {
            dispatchValidation({
                type: 'onValidationError',
                payload: {
                    name: 'filesToSignError',
                    value: filesToSignError
                }
            });
            isFormValid = false;
        }
        var smokeTestResultError = smokeTestResultValidator(signingTypeData === null || signingTypeData === void 0 ? void 0 : signingTypeData.template, signingTypeData === null || signingTypeData === void 0 ? void 0 : signingTypeData.name, state.smokeTestResult);
        if (smokeTestResultError) {
            dispatchValidation({
                type: 'onValidationError',
                payload: {
                    name: 'smokeTestResultError',
                    value: smokeTestResultError
                }
            });
            isFormValid = false;
        }
        return isFormValid;
    };
    var onFormSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
        var request, formData, res, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    request = 'Request';
                    dispatchValidation({ type: 'onReset' });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    if (!signFormValidator()) return [3 /*break*/, 4];
                    setIsSubmitting(true);
                    formData = mapSigningSubmissionData(state);
                    return [4 /*yield*/, prepareRequest(authClient, formData)];
                case 2:
                    res = _a.sent();
                    if (res.data.requestId)
                        request = res.data.requestId;
                    setIsSubmitting(false);
                    toast.success("RequestID: " + res.data.requestId + " has been created. Uploading files to blob. You will be notified on completion");
                    inProgress(res.data.requestId + '-Create', "Creating Request " + res.data.requestId);
                    navigateToMyRequestsPage();
                    return [4 /*yield*/, uploadFilesToSign(res.data)];
                case 3:
                    _a.sent();
                    success(res.data.requestId + '-Create', "Created Request " + res.data.requestId);
                    _a.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    e_1 = _a.sent();
                    setIsSubmitting(false);
                    error(request + '-Create', "Failed to create " + request);
                    toast.error('Failed to submit request');
                    telemetryClient.trackException({
                        exception: e_1,
                        properties: { 'Component Name': 'SignRequestForm', 'Method Name': 'onFormSubmit' },
                    });
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var uploadFilesToSign = function (submissionData) { return __awaiter(_this, void 0, void 0, function () {
        var sas, blob;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    submissionData.fileToSignMetadataList = state.filesToSign.map(function (f, i) {
                        var _a;
                        return {
                            filesToSign: f,
                            fileToSignName: f.name,
                            fileToSignPath: '',
                            fileCreationDate: null,
                            fileLastModifiedDate: null,
                            fileOwner: '',
                            machineName: '',
                            smokeTestFile: state.smokeTestResult[i],
                            smokeTestFileName: (_a = state.smokeTestResult[i]) === null || _a === void 0 ? void 0 : _a.name,
                            smokeTestFilePath: '',
                            signedFileName: '',
                            compressedFileName: null,
                            blobSettings: {}
                        };
                    });
                    return [4 /*yield*/, prepareSasRequest(authClient, submissionData)];
                case 1:
                    sas = _a.sent();
                    submissionData.fileToSignMetadataList.map(function (f, i) {
                        f.fileToSignSas = sas.data[i].fileToSignSas;
                        f.smokeTestFileSas = sas.data[i].smokeTestFileSas;
                    });
                    blob = new AutoSignBlobStorageService();
                    return [4 /*yield*/, blob.uploadFilesToSign(submissionData)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, submitRequest(authClient, submissionData)];
                case 3:
                    _a.sent();
                    toast.success("RequestID: " + submissionData.requestId + " has been submitted successfully.");
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("form", null,
        isSubmitting && React.createElement(Loading, null),
        React.createElement(Stack, null,
            isSponsorRequired &&
                React.createElement(Stack, { horizontal: true },
                    React.createElement(Stack.Item, { styles: stackItemRequestHeaderStyles },
                        React.createElement(Stack, { horizontal: true },
                            React.createElement(Label, { required: true },
                                React.createElement("strong", null, " FTE Sponsor(s):")),
                            isSponsorRequired && React.createElement(TooltipHost, { closeDelay: 500, content: "Type in your FTE Sponsor(s) name or mail to search." },
                                React.createElement(IconButton, { title: 'FTE Sponsors', iconProps: { iconName: 'Info' } })))),
                    React.createElement(Stack.Item, { styles: stackItemRequestContentStyles },
                        React.createElement(UserPicker, { onChange: function (mails) { return onUserPickerChange('sponsors', mails); }, validationMessage: validationState.sponsorsError }))),
            displayState.enableProduct && React.createElement(ProductDropdown, { onChange: onProductChange, styles: dropdownStyles }),
            displayState.displaySigningTypeCode &&
                React.createElement(Stack, { horizontal: true },
                    React.createElement(Stack.Item, { styles: stackItemRequestHeaderStyles },
                        React.createElement(Label, { required: true },
                            " ",
                            React.createElement("strong", null, " Signing Type Code:"))),
                    React.createElement(Stack.Item, { styles: stackItemRequestContentStyles },
                        React.createElement(Stack, { horizontal: true },
                            React.createElement(TextField, { resizable: true, styles: textFieldStyles, name: 'signingType', onChange: function (e, n) { return onSigningTypeCodeChange1(n); }, errorMessage: validationState.signingTypeError, ariaLabel: "Signing Type" }),
                            React.createElement(DefaultButton, { iconProps: { iconName: 'Accept' }, onClick: onSigningTypeCodeChange, ariaLabel: 'Check Signing Type' })))),
            displayState.displaySigningType && React.createElement(SigningTypeDropdown, { productId: state.productID, onChange: onSigningTypeChange, styles: dropdownStyles, productName: state.productName }),
            displayState.displaySerialNumber &&
                React.createElement(Stack, { horizontal: true },
                    React.createElement(Stack.Item, { styles: stackItemRequestHeaderStyles },
                        React.createElement(Label, { required: isSerialNumberRequired(signingTypeData === null || signingTypeData === void 0 ? void 0 : signingTypeData.template) },
                            " ",
                            React.createElement("strong", null, " Serial Number: \u2003"))),
                    React.createElement(Stack.Item, { styles: stackItemRequestContentStyles },
                        React.createElement(TextField, { resizable: true, styles: textFieldStyles, name: 'serialNumber', onChange: function (e, n) { return onValueChange('serialNumber', n); }, maxLength: 50, errorMessage: validationState.serialNumberError, ariaLabel: "Serial Number" }))),
            displayState.displayBusinessJustification &&
                React.createElement(Stack, { horizontal: true },
                    React.createElement(Stack.Item, { styles: stackItemRequestHeaderStyles },
                        React.createElement(Label, { required: true },
                            " ",
                            React.createElement("strong", null, " Business Justification:"))),
                    React.createElement(Stack.Item, { styles: stackItemRequestContentStyles },
                        React.createElement(TextField, { multiline: true, autoAdjustHeight: true, styles: textFieldStyles, maxLength: 2000, errorMessage: validationState.businessJustificationError, onChange: function (e, n) { return onValueChange('businessJustification', n); }, ariaLabel: "Business Justification" }))),
            displayState.displayFilesToSign &&
                React.createElement(Stack, { horizontal: true },
                    React.createElement(Stack.Item, { styles: stackItemRequestHeaderStyles },
                        React.createElement(Stack, { horizontal: true },
                            React.createElement(Label, { required: signingTypeData ? signingTypeData.inputFileRequired : false },
                                " ",
                                React.createElement("strong", null, " Files To Sign:")))),
                    React.createElement(Stack.Item, { styles: stackItemRequestContentStyles },
                        React.createElement(FileUploader, { name: "filesToSign", files: state.filesToSign, onFileAdd: onFileToSignAdd, onFileRemove: onFileToSignRemove, validationMessage: validationState.filesToSignError }))),
            displayState.displayArtifactVersion &&
                React.createElement(Stack, { horizontal: true },
                    React.createElement(Stack.Item, { styles: stackItemRequestHeaderStyles },
                        React.createElement(Label, { required: signingTypeData ? signingTypeData.template == 'DBG' : false },
                            " ",
                            React.createElement("strong", null, " Software/Firmware Version: \u2003"))),
                    React.createElement(Stack.Item, { styles: stackItemRequestContentStyles },
                        React.createElement(TextField, { resizable: true, styles: textFieldStyles, onChange: function (e, n) { return onValueChange('artifactVersion', n); }, maxLength: 100, errorMessage: validationState.artifactVersionError, ariaLabel: "Artifact Version" }))),
            displayState.displayArtifactDescription &&
                React.createElement(Stack, { horizontal: true },
                    React.createElement(Stack.Item, { styles: stackItemRequestHeaderStyles },
                        React.createElement(Label, null,
                            " ",
                            React.createElement("strong", null, " Software/Firmware Description: \u2003"))),
                    React.createElement(Stack.Item, { styles: stackItemRequestContentStyles },
                        React.createElement(TextField, { resizable: true, styles: textFieldStyles, onChange: function (e, n) { return onValueChange('artifactDescription', n); }, maxLength: 250, ariaLabel: "Artifact Description" }))),
            displayState.displaySmokeTestResult &&
                React.createElement(Stack, { horizontal: true },
                    React.createElement(Stack.Item, { styles: stackItemRequestHeaderStyles },
                        React.createElement(Stack, { horizontal: true },
                            React.createElement(Label, { required: isSmokeTestResultRequired(signingTypeData === null || signingTypeData === void 0 ? void 0 : signingTypeData.template, signingTypeData === null || signingTypeData === void 0 ? void 0 : signingTypeData.name) },
                                " ",
                                React.createElement("strong", null, " Smoke Test Result:")),
                            React.createElement(TooltipHost, { closeDelay: 500, content: "Upload the file that\u00A0contains smoke test result. This can be a\u00A0word document,\u00A0email file, or text file." },
                                React.createElement(IconButton, { title: 'Smoke Test Result', iconProps: { iconName: 'Info' } })))),
                    React.createElement(Stack.Item, { styles: stackItemRequestContentStyles },
                        React.createElement(FileUploader, { name: "smokeTestResult", files: state.smokeTestResult, onFileAdd: onSmokeTestResultAdd, onFileRemove: onSmokeTestResultRemove, validationMessage: validationState.smokeTestResultError }))),
            displayState.displayEmailCc &&
                React.createElement(Stack, { horizontal: true },
                    React.createElement(Stack.Item, { styles: stackItemRequestHeaderStyles },
                        React.createElement(Label, null,
                            " ",
                            React.createElement("strong", null, " Email CC: \u2003"))),
                    React.createElement(Stack.Item, { styles: stackItemRequestContentStyles },
                        React.createElement(UserPicker, { onChange: function (mails) { return onUserPickerChange('mailCC', mails); }, validationMessage: validationState.mailCcError }))),
            displayState.displaySubmitButton &&
                React.createElement(Stack, { horizontal: true },
                    React.createElement(Stack.Item, { styles: stackItemRequestContentStyles },
                        React.createElement(PrimaryButton, { text: "Submit", onClick: onFormSubmit }))))));
}
