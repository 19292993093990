var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var Initial_Sign_Request = {
    requestID: null,
    productID: undefined,
    productName: "",
    sponsors: null,
    signingType: "",
    serialNumber: "",
    businessJustification: "",
    artifactVersion: "",
    artifactDescription: "",
    mailCC: null,
    requestStatus: "",
    filesToSign: [],
    smokeTestResult: []
};
export var signFormReducer = function (state, action) {
    var _a, _b, _c, _d, _e, _f;
    switch (action.type) {
        case 'onProductChange':
            return __assign(__assign({}, Initial_Sign_Request), (_a = { sponsors: state.sponsors, productID: state.productID, productName: state.productName }, _a[action.payload.name] = action.payload.value, _a));
        case 'onValueChange':
            return __assign(__assign({}, state), (_b = {}, _b[action.payload.name] = action.payload.value, _b));
        case 'onFileToSignAdd':
            return __assign(__assign({}, state), (_c = {}, _c[action.payload.name] = __spreadArray(__spreadArray([], state.filesToSign, true), [action.payload.value], false), _c));
        case 'onFileToSignRemove':
            return __assign(__assign({}, state), (_d = {}, _d[action.payload.name] = state.filesToSign.filter(function (f) { return f != action.payload.value; }), _d));
        case 'onSmokeTestResultAdd':
            return __assign(__assign({}, state), (_e = {}, _e[action.payload.name] = __spreadArray(__spreadArray([], state.smokeTestResult, true), [action.payload.value], false), _e));
        case 'onSmokeTestResultRemove':
            return __assign(__assign({}, state), (_f = {}, _f[action.payload.name] = state.smokeTestResult.filter(function (f) { return f != action.payload.value; }), _f));
        default: return state;
    }
};
