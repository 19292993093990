var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { ConstrainMode } from '@fluentui/react/lib/DetailsList';
import { useState, useEffect } from 'react';
import { DetailsList } from '@fluentui/react/lib/DetailsList';
import { IconButton } from '@fluentui/react/lib/Button';
import { ComponentContext } from '@employee-experience/common/lib/Context/ComponentContext';
import { Panel, PanelType, CommandBar, Fabric, TooltipHost, SelectionMode, ScrollablePane, TooltipOverflowMode, } from '@fluentui/react';
import { toast } from 'react-toastify';
import '!style-loader!css-loader!react-toastify/dist/ReactToastify.css';
import * as Styled from '../../shared/layout';
import { useHistory } from 'react-router-dom';
import { httpGet } from '../../shared/APICalls';
import { hostStyles } from './AdvancedSearchStyles';
import CreateSigningRequest from './CreateSigningRequest';
var appConfig = require('../../Config/appConfig.js').appConfig;
var EventEmitter = require('../CommonUtilities/EventEmitter.js').EventEmitter;
var EventType = require('../CommonUtilities/EventEmitter.js').EventType;
var baseUrl = appConfig.AppSettings.APIUrl;
import { useBoolean } from '@uifabric/react-hooks';
var SearchResultsData = function (props) {
    var resourceId = appConfig.AppSettings.AppClientId;
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var history = useHistory();
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var _b = useBoolean(false), isOpenForCreate = _b[0], _c = _b[1], openPanelForCreate = _c.setTrue, dismissPanelForCreate = _c.setFalse;
    var _d = useBoolean(false), isOpenToVeiewRequest = _d[0], _e = _d[1], openPanelToVeiewRequest = _e.setTrue, dismissPanelToVeiewRequest = _e.setFalse;
    var _f = useState(0), requestIdData = _f[0], setRequestIdData = _f[1];
    var _g = useState(''), requestorAliasData = _g[0], setRequestorAliasData = _g[1];
    var _h = useState(sessionStorage.getItem('searchText') || ''), searchText = _h[0], setSearchText = _h[1];
    React.useEffect(function () {
        sessionStorage.setItem('searchText', searchText);
    }, [searchText]);
    var _j = useState([]), searchResults = _j[0], setSearchResults = _j[1];
    var _k = useState([]), defaultSearchResults = _k[0], setDefaultSearchResults = _k[1];
    var _l = useState([]), columns = _l[0], setColumns = _l[1];
    var lastStatusIcons = { marginTop: -7 };
    var lastStatusCancelIcons = { marginTop: -7, backgroundColor: '#ffebee' };
    var _m = React.useContext(ComponentContext), telemetryClient = _m.telemetryClient, authClient = _m.authClient;
    var _o = React.useState(''), loggedInUser = _o[0], setLoggedInUser = _o[1];
    var _p = useState(''), acccessToken = _p[0], setAcccessToken = _p[1];
    useEffect(function () {
        authClient.getUserId().then(function (res) {
            setLoggedInUser(res);
        });
        authClient.acquireToken(__CLIENT_API_SCOPES__).then(function (res) {
            setAcccessToken(res);
        });
    });
    var _items = [
        {
            key: 'newItem',
            text: "New Signing Request",
            cacheKey: 'myCacheKey',
            iconProps: { iconName: 'Add' },
            onClick: function () {
                openPanelForCreate();
                setRequestorAliasData(loggedInUser);
            },
        },
    ];
    var handleColumnClick = function (ev, column) {
        var newColumns = columns.slice();
        var currColumn = newColumns.filter(function (currCol) { return column.key === currCol.key; })[0];
        newColumns.forEach(function (newCol) {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            }
            else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        var newItems = _copyAndSort(_items, currColumn.fieldName, currColumn.isSortedDescending);
        setColumns(newColumns);
    };
    useEffect(function () {
        toast.configure({
            position: 'top-center',
            autoClose: 5000,
            bodyClassName: 'toast-body',
            hideProgressBar: true,
            style: { marginTop: 50 },
        });
    }, []);
    var onRenderWithToolTip = function (content, index, key) {
        return React.createElement(TooltipHost, { overflowMode: TooltipOverflowMode.Parent, content: content, styles: hostStyles },
            React.createElement("span", { "aria-describedby": key + index }, content));
    };
    var column = [
        {
            key: 'requestID',
            name: 'RequestID',
            ariaLabel: 'requestID',
            fieldName: 'requestID',
            isResizable: true,
            minWidth: 100,
            maxWidth: 100,
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.requestID); },
        },
        {
            key: 'requestor',
            name: 'Requestor',
            ariaLabel: 'requestor',
            fieldName: 'requestor',
            isResizable: true,
            minWidth: 120,
            maxWidth: 150,
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.requestor); },
        },
        {
            key: 'sponsor',
            name: 'Sponsor',
            ariaLabel: 'sponsor',
            fieldName: 'sponsor',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.sponsor); },
        },
        {
            key: 'approver',
            name: 'Approver',
            ariaLabel: 'approver',
            fieldName: 'approver',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.approver); },
        },
        {
            key: 'businessJustification',
            name: 'Business Justification',
            ariaLabel: 'businessJustification',
            fieldName: 'businessJustification',
            isResizable: true,
            minWidth: 100,
            maxWidth: 250,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.businessJustification); },
        },
        {
            key: 'productName',
            name: 'Product Name',
            ariaLabel: 'productName',
            fieldName: 'productName',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.productName); },
        },
        {
            key: 'signingType',
            name: 'Signing Type',
            ariaLabel: 'signingType',
            fieldName: 'signingType',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.signingType); },
        },
        {
            key: 'signingDetail',
            name: 'Signing Detail',
            ariaLabel: 'signingDetail',
            fieldName: 'signingDetail',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.signingDetail); },
        },
        {
            key: 'statusCode',
            name: 'Status',
            ariaLabel: 'statusCode',
            fieldName: 'statusCode',
            isResizable: true,
            minWidth: 100,
            maxWidth: 100,
            data: 'string',
            onRender: function (item) { return (React.createElement("div", null,
                React.createElement("span", { hidden: item.statusCode != 'Processing' && item.statusCode != 'Preparing' && item.statusCode != 'Initialized' },
                    React.createElement(IconButton, { iconProps: { iconName: 'ProgressRingDots' }, title: 'Processing', style: lastStatusIcons })),
                React.createElement("span", { hidden: item.statusCode != 'Succeeded' },
                    React.createElement(IconButton, { iconProps: { iconName: 'CompletedSolid' }, title: 'Succeeded', style: lastStatusIcons, styles: { icon: { color: 'green' } } })),
                React.createElement("span", { hidden: item.statusCode != 'Failed' },
                    React.createElement(IconButton, { iconProps: { iconName: 'ErrorBadge' }, title: 'Failed', style: lastStatusIcons, styles: { icon: { color: 'red' } } })),
                React.createElement("span", { hidden: item.statusCode != 'Cancelled' },
                    React.createElement(IconButton, { iconProps: { iconName: 'Cancel' }, title: 'Cancelled', style: lastStatusCancelIcons, styles: { icon: { color: 'red' } } })),
                React.createElement("span", { hidden: item.statusCode != 'INDELAY' },
                    React.createElement(IconButton, { iconProps: { iconName: 'CirclePause' }, title: 'InDelay', style: lastStatusIcons, styles: { icon: { color: 'red' } } })),
                React.createElement("span", { hidden: item.statusCode != 'Completed' && item.statusCode != 'Fulfilled' },
                    React.createElement(IconButton, { iconProps: { iconName: 'Completed' }, title: 'Completed', style: lastStatusIcons, styles: { icon: { color: 'green' } } })),
                React.createElement("span", { hidden: item.statusCode != 'New' },
                    React.createElement(IconButton, { iconProps: { iconName: 'NewAnalyticsQuery' }, title: 'New', style: lastStatusIcons })),
                React.createElement("span", null, item.statusCode))); }
        },
        {
            key: 'rejectReason',
            name: 'Reject Reason',
            ariaLabel: 'rejectReason',
            fieldName: 'rejectReason',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.rejectReason); },
        },
        {
            key: 'action',
            name: 'Actions',
            ariaLabel: 'Actions',
            fieldName: 'action',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            onRender: function (item) { return (React.createElement("div", null,
                React.createElement(IconButton, { onClick: function () {
                        setRequestIdData(item.requestID);
                        openPanelToVeiewRequest(item.requestID);
                    }, iconProps: { iconName: 'ViewOriginal' }, title: 'Signing Request Details' }),
                React.createElement(IconButton, { onClick: function () {
                        openPanel();
                    }, iconProps: { iconName: 'DocumentApproval' }, title: 'Approve Signing Request' }),
                React.createElement(IconButton, { onClick: function () {
                        openPanel();
                    }, iconProps: { iconName: 'ViewOriginal' }, title: 'Reject Signing Request' }))); },
        },
    ];
    var SearchResults = function (searchId) { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = "".concat(baseUrl, "/signing-approvals/GetSigningRequestDetails?searchId=").concat(searchId);
            httpGet(_httpClient, acccessToken, url, function (response) {
                setColumns(column);
                setSearchResults(response.data);
                setDefaultSearchResults(response.data);
            }, function (error) {
                toast.error(error.data.Message);
            });
            return [2 /*return*/];
        });
    }); };
    var AdvancedSearchResults = function (searchId) { return __awaiter(void 0, void 0, void 0, function () {
        var fromDate, toDate, signingType, url;
        return __generator(this, function (_a) {
            fromDate = dateToISOLikeButLocal(new Date(sessionStorage.getItem('fromDate')));
            toDate = dateToISOLikeButLocal(new Date(sessionStorage.getItem('toDate')));
            signingType = sessionStorage.getItem('signingType');
            if (fromDate <= toDate) {
                url = "".concat(baseUrl, "/signing-approvals/GetSigningRequest?searchId=").concat(searchId, "&fromDate=").concat(fromDate, "&toDate=").concat(toDate, "&signingType=").concat(signingType);
                httpGet(_httpClient, acccessToken, url, function (response) {
                    setColumns(column);
                    setSearchResults(response.data);
                    setDefaultSearchResults(response.data);
                }, function (error) {
                    toast.error(error.data.Message);
                });
            }
            else {
                toast.error("Please ensure FromDate should be less than or equal to ToDate");
            }
            ;
            return [2 /*return*/];
        });
    }); };
    var DefaultSearchResults = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = "".concat(baseUrl, "/signing-approvals/GetAllSigningRequests");
            httpGet(_httpClient, acccessToken, url, function (response) {
                setColumns(column);
                setPendingRequests(response.data);
            }, function (error) {
                toast.error(error.data.Message);
            });
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        DefaultSearchResults();
    }, [loggedInUser, acccessToken]);
    function _copyAndSort(item, columnKey, isSortedDescending) {
        var key = columnKey;
        return item.slice(0).sort(function (a, b) { return ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1); });
    }
    var onSubmit = function (ev) {
        ev.preventDefault();
    };
    var createSigningRequestOnPanelSubmit = function (item) {
        dismissPanelForCreate();
        DefaultSearchResults();
    };
    var onSearchHandler = function (event) {
        if (event && event.target) {
            var queryText = event.target.value;
            setSearchText(queryText);
            if (queryText) {
                var filteredData = filterData(queryText);
                setDefaultSearchResults(filteredData);
            }
            else {
                setDefaultSearchResults(searchResults);
            }
        }
        else {
            setSearchText('');
            setDefaultSearchResults(searchResults);
        }
    };
    var filterData = function (queryText) {
        return searchResults.filter(function (_items) {
            return (_items.requestID && _items.requestID.toString().toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.requestor && _items.requestor.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.sponsor && _items.sponsor.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.approver && _items.approver.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.productName && _items.productName.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.signingDetail && _items.signingDetail.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.signingType && _items.signingType.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.statusCode && _items.statusCode.toLowerCase().includes(queryText.trim().toLowerCase()));
        });
    };
    function dateToISOLikeButLocal(date) {
        var offsetMs = date.getTimezoneOffset() * 60 * 1000;
        var msLocal = date.getTime() - offsetMs;
        var dateLocal = new Date(msLocal);
        var iso = dateLocal.toISOString();
        var isoLocal = iso.slice(0, 10);
        return isoLocal;
    }
    var onClickGetDataHandler = function (event) {
        if (event.target.value.length > 0) {
            SearchResults(event.target.value);
        }
        else {
            toast.error("Please ensure Proper search criteria in Searchbox");
        }
    };
    useEffect(function () {
        EventEmitter.subscribe(EventType.onSearch, onSearchHandler);
        return function cleanup() {
            EventEmitter.unsubscribe(EventType.onSearch, onSearchHandler);
        };
    });
    React.useEffect(function () {
        EventEmitter.subscribe(EventType.onClick, onClickGetDataHandler);
        return function cleanup() {
            EventEmitter.unsubscribe(EventType.onClick, onClickGetDataHandler);
        };
    });
    return (React.createElement(Styled.listContainer, null,
        React.createElement(Fabric, null,
            React.createElement(CommandBar, { items: _items, ariaLabel: "Use left and right arrow keys to navigate between commands", style: { marginLeft: '73%', marginTop: -40 } }),
            React.createElement("div", null,
                React.createElement(Panel, { headerText: "Create Signing Request", isOpen: isOpenForCreate, onDismiss: dismissPanelForCreate, isLightDismiss: true, type: PanelType.medium, 
                    // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                    closeButtonAriaLabel: "Close" },
                    React.createElement(CreateSigningRequest, { requestorAlias: requestorAliasData, onPanelSubmit: createSigningRequestOnPanelSubmit }))),
            React.createElement("div", null,
                React.createElement(Panel, { headerText: "View Signing Request", isOpen: isOpenToVeiewRequest, onDismiss: dismissPanelToVeiewRequest, isLightDismiss: true, type: PanelType.medium, closeButtonAriaLabel: "Close" },
                    React.createElement(SigningRequestDetails, { requestIdData: requestIdData }))),
            React.createElement(ScrollablePane, { style: { position: 'relative', height: '60vh' } },
                React.createElement(DetailsList, { columns: columns, items: defaultSearchResults, constrainMode: ConstrainMode.unconstrained, ariaLabel: "Value", ariaLabelForSelectAllCheckbox: "Toggle selection for all items", ariaLabelForSelectionColumn: "Toggle selection", checkButtonAriaLabel: "Row checkbox", selectionMode: SelectionMode.none })))));
};
export default SearchResultsData;
