import { ComponentContext } from "@employee-experience/common";
import { Dropdown, Label, Stack } from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getSigningTypes } from "../../Services/SigningSubmissionApi";
import { Loading } from "../CommonUtilities/Loading";
import { stackItemRequestContentStyles, stackItemRequestHeaderStyles } from "../ManageSigning/Styles";
export function SigningTypeDropdown(props) {
    var _a = useState([]), signingTypeOptions = _a[0], setSigningTypeOptions = _a[1];
    var _b = useState(), error = _b[0], setError = _b[1];
    var _c = useContext(ComponentContext), telemetryClient = _c.telemetryClient, authClient = _c.authClient;
    var _d = useState(false), isLoading = _d[0], setIsLoading = _d[1];
    useEffect(function () {
        setIsLoading(true);
        var temp = [];
        if (props.productId) {
            getSigningTypes(authClient, props.productId).then(function (res) {
                res.data.map(function (signingType) {
                    temp.push({
                        key: signingType.name,
                        text: signingType.detail,
                        data: signingType
                    });
                });
                setSigningTypeOptions(temp);
                setIsLoading(false);
            }).catch(function (e) {
                setIsLoading(false);
                setError(e);
                temp.push({ key: 0, text: 'Failed to load signing types' });
                setSigningTypeOptions(temp);
                toast.error("Failed to load signing types for product ".concat(props.productName), { toastId: "failedToLoadSigningTypesFor".concat(props.productName) });
                telemetryClient.trackException({
                    exception: e,
                    properties: { 'Component Name': 'SigningTypeDropdown', 'Method Name': 'useEffect' },
                });
            });
        }
    }, [props.productId, props.productName]);
    return (React.createElement(React.Fragment, null,
        signingTypeOptions.length > 0 && !isLoading &&
            (React.createElement(Stack, { horizontal: true },
                React.createElement(Stack.Item, { styles: stackItemRequestHeaderStyles },
                    React.createElement(Label, null,
                        " ",
                        React.createElement("strong", null, " Signing Type: \u2003"))),
                React.createElement(Stack.Item, { styles: stackItemRequestContentStyles },
                    React.createElement(Dropdown, { "aria-label": "Signing Types dropdown", styles: props.styles, options: signingTypeOptions, onChange: function (e, o) { return props.onChange(o); } })))),
        isLoading && !error && React.createElement(Loading, null)));
}
