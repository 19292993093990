var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useId, useBoolean } from '@uifabric/react-hooks';
import { ContextualMenu, DefaultButton, IconButton, Stack, TextField, PrimaryButton, Breadcrumb, Dialog, DialogFooter, DialogType, TooltipHost, } from '@fluentui/react';
import { Text } from '@fluentui/react/lib/Text';
import * as Styled from '../../shared/layout';
import { ComponentContext, withContext } from '@employee-experience/common';
import { useHistory, withRouter } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { httpGet, httpPut } from '../../shared/APICalls';
import { toast } from 'react-toastify';
import '!style-loader!css-loader!react-toastify/dist/ReactToastify.css';
import { hostStyles } from './AdvancedSearchStyles';
import DownloadFilesWithPrimaryButton from '../CommonUtilities/DownloadFilesWithPrimaryButton';
var EventEmitter = require('../CommonUtilities/EventEmitter.js').EventEmitter;
var EventType = require('../CommonUtilities/EventEmitter.js').EventType;
var appConfig = require('../../Config/appConfig.js').appConfig;
var baseUrl = appConfig.AppSettings.APIUrl;
var SigningRequestDetails = function (props) {
    var _a = useState(''), requestId = _a[0], setRequestId = _a[1];
    var titleId = useId('title');
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var _b = useState([{
            requestID: Number,
            requestor: '',
            approver: '',
            sponsor: '',
            sponsors: '',
            requestDateTime: null,
            businessJustification: '',
            productName: '',
            signingType: '',
            signingDetail: '',
            serialNumber: '',
            firmwareDescription: '',
            firmwareVersion: '',
            fileInfoStr: '',
            statusCode: '',
            requestState: '',
            rejectReason: '',
            infoMessage: '',
            filesToSign: '',
            smokeTestFiles: '',
            signatureFiles: '',
            uploadedBy: ''
        }]), requestDetails = _b[0], setRequestDetails = _b[1];
    var _c = useState(''), rejectReason = _c[0], setRejectReason = _c[1];
    var _d = useState([]), defaultRequestDetails = _d[0], setDefaultRequestDetails = _d[1];
    var _e = useState([]), columns = _e[0], setColumns = _e[1];
    var stackStyles = { root: { width: 500 } };
    var horizontalGapStackTokens = {
        childrenGap: 50,
        padding: 10,
    };
    var history = useHistory();
    var _f = React.useState(true), hideButtonApprove = _f[0], setHideButtonApprove = _f[1];
    var _g = React.useState(true), hideButtonReject = _g[0], setHideButtonReject = _g[1];
    var _h = React.useState(true), hideButtonCancel = _h[0], setHideButtonCancel = _h[1];
    var _j = React.useState(true), hideButtonCompleteSigning = _j[0], setHideButtonCompleteSigning = _j[1];
    var _k = React.useState(true), hideButtonResign = _k[0], setHideButtonResign = _k[1];
    var _l = React.useState(true), disableButtonApprove = _l[0], setDisableButtonApprove = _l[1];
    var _m = React.useState(true), disableButtonReject = _m[0], setDisableButtonReject = _m[1];
    var _o = React.useState(true), disableButtonCancel = _o[0], setDisableButtonCancel = _o[1];
    var _p = React.useState(true), disableButtonCompleteSigning = _p[0], setDisableButtonCompleteSigning = _p[1];
    var _q = React.useState(true), disableButtonResign = _q[0], setDisableButtonResign = _q[1];
    var _r = React.useState(''), approverMsg = _r[0], setapproverMsg = _r[1];
    var _s = useState([]), requestBlobMetadata = _s[0], setRequestBlobMetadata = _s[1];
    var _t = useState(['']), defaultRequestBlobMetadata = _t[0], setDefaultRequestBlobMetadata = _t[1];
    var _u = React.useState(true), hideFileControls = _u[0], setHideFileControls = _u[1];
    var _v = React.useState(true), hideSignedFileControls = _v[0], setHideSignedFileControls = _v[1];
    var _w = React.useState(true), hideRejectReasonControls = _w[0], setHideRejectReasonControls = _w[1];
    var tooltipId = useId('tooltip');
    var calloutProps = { gapSpace: 0 };
    var itemsWithHref = [
        {
            text: 'Home',
            key: 'f1',
            onClick: function () {
                navigateToHomePage();
            },
        },
        {
            text: "Request Details - ".concat(requestId),
            key: 'f5',
            isCurrentItem: true,
        },
    ];
    var _x = React.useContext(ComponentContext), telemetryClient = _x.telemetryClient, authClient = _x.authClient;
    var _y = React.useState(''), loggedInUser = _y[0], setLoggedInUser = _y[1];
    var _z = useState(''), acccessToken = _z[0], setAcccessToken = _z[1];
    useEffect(function () {
        authClient.getUserId().then(function (res) {
            setLoggedInUser(res);
        });
        authClient.acquireToken(__CLIENT_API_SCOPES__).then(function (res) {
            setAcccessToken(res);
        });
    });
    useEffect(function () {
        var split = location.pathname.split("/");
        setRequestId(split[split.length - 1]);
    }, [props.requestIdData]);
    useEffect(function () {
        toast.configure({
            position: 'top-center',
            autoClose: 5000,
            bodyClassName: 'toast-body',
            hideProgressBar: true,
            style: { marginTop: 50 },
        });
    }, []);
    var dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
    };
    var modalPropsStyles = { main: { maxWidth: 500 } };
    var approveDialogContentProps = {
        type: DialogType.normal,
        title: 'Approve Request',
        subText: 'Do you want to Approve this request?',
    };
    var rejectDialogContentProps = {
        type: DialogType.normal,
        title: 'Reject Request',
        subText: 'Do you want to Reject this request?',
    };
    var resignDialogContentProps = {
        type: DialogType.normal,
        title: 'Re-Sign Request',
        subText: 'Do you want to Re-Sign this request?',
    };
    var cancelDialogContentProps = {
        type: DialogType.normal,
        title: 'Cancel Request',
        subText: 'Do you want to Cancel this request?',
    };
    var _0 = useBoolean(true), hideApproveReqDialog = _0[0], toggleHideApproveReqDialog = _0[1].toggle;
    var _1 = useBoolean(true), hideRejectReqDialog = _1[0], toggleHideRejectReqDialog = _1[1].toggle;
    var _2 = useBoolean(true), hideReSignReqDialog = _2[0], toggleHideReSignReqDialog = _2[1].toggle;
    var _3 = useBoolean(true), hideCancelReqDialog = _3[0], toggleHideCancelReqDialog = _3[1].toggle;
    var _4 = useBoolean(false), isDraggable = _4[0], toggleIsDraggable = _4[1].toggle;
    var modalProps = React.useMemo(function () { return ({
        isBlocking: true,
        styles: modalPropsStyles,
        dragOptions: isDraggable ? dragOptions : undefined,
    }); }, [isDraggable]);
    useEffect(function () {
        SearchResults(requestId);
    }, [requestId, loggedInUser, acccessToken]);
    useEffect(function () {
        if (requestId != "" && acccessToken != "") {
            var url = "".concat(baseUrl, "/signing-approvals/GetRequestBlobMetadata?requestId=").concat(requestId);
            httpGet(_httpClient, acccessToken, url, function (response) {
                setRequestBlobMetadata(response.data);
                var items = [];
                var itemStr = '';
                response.data.map(function (item, i) {
                    updateState("{ FileToSignName: ".concat(item.fileToSignName, ", FileCreatedDate: ").concat(item.fileCreatedDate, ",\n                                FileModifiedDate: ").concat(item.FileModifiedDate, ", FileOwner: ").concat(item.fileOwner, ", Machine: ").concat(item.machine, ", FileToSignLength: ").concat(item.FileToSignLength, " }; \n"));
                });
            }, function (error) {
                telemetryClient.trackException({
                    exception: error.data.Message,
                    properties: { 'Component Name': 'SigningRequestDetails', 'Method Name': 'GetRequestBlobMetadata', },
                });
            });
        }
    }, [requestId]);
    var updateState = function (newState) {
        setDefaultRequestBlobMetadata(function (defaultRequestBlobMetadata) { return defaultRequestBlobMetadata.concat(newState); });
    };
    var RequestAction = function (action) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (action == 'Approve') {
                approveSigningRequest(action);
            }
            else if (action == 'Reject') {
                rejectSigningRequest(action);
            }
            else if (action == 'Cancel') {
                cancelSigningRequest(action);
            }
            return [2 /*return*/];
        });
    }); };
    var approveSigningRequest = function (action) { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            if (requestId != "" && action != "" && acccessToken != "") {
                url = "".concat(baseUrl, "/signing-approvals/RequestAction?requestId=").concat(requestId, "&&approverAction=").concat(action);
                httpPut(_httpClient, acccessToken, url, function (response) {
                    toast.success("RequestID: " + requestId + " has been Approved successfully and it will be proceed for signing");
                    SearchResults(requestId);
                }, function (error) {
                    toast.error("Error while approving RequestID: " + requestId + " and error is :" + error.statusText);
                    telemetryClient.trackException({
                        exception: error.data.Message,
                        properties: { 'Component Name': 'SigningRequestDetails', 'Method Name': 'approveSigningRequest', },
                    });
                }, JSON.stringify({}));
            }
            return [2 /*return*/];
        });
    }); };
    var rejectSigningRequest = function (action) { return __awaiter(void 0, void 0, void 0, function () {
        var errorObj, url;
        return __generator(this, function (_a) {
            errorObj = validateRejectRequest();
            if (errorObj.isPassed) {
                if (requestId != "" && action != "" && acccessToken != "") {
                    url = "".concat(baseUrl, "/signing-approvals/RequestAction?requestId=").concat(requestId, "&&approverAction=").concat(action, "&&rejectReason=").concat(rejectReason);
                    httpPut(_httpClient, acccessToken, url, function (response) {
                        toast.success("RequestID: " + requestId + " has been Rejected.");
                        SearchResults(requestId);
                    }, function (error) {
                        toast.error("Error while rejecting RequestId: " + requestId + " and error is :" + error.statusText);
                        telemetryClient.trackException({
                            exception: error.data.Message,
                            properties: { 'Component Name': 'SigningRequestDetails', 'Method Name': 'rejectSigningRequest', },
                        });
                    }, JSON.stringify({}));
                }
            }
            else {
                toast.error(errorObj.errorMessage);
            }
            return [2 /*return*/];
        });
    }); };
    var cancelSigningRequest = function (action) { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            if (requestId != "" && action != "" && acccessToken != "") {
                url = "".concat(baseUrl, "/signing-approvals/RequestAction?requestId=").concat(requestId, "&&approverAction=").concat(action);
                httpPut(_httpClient, acccessToken, url, function (response) {
                    toast.success("RequestID: " + requestId + " has been Cancelled successfully");
                    SearchResults(requestId);
                }, function (error) {
                    toast.error("Error while Cancelling RequestId: " + requestId + " and error is :" + error);
                    telemetryClient.trackException({
                        exception: error.data.Message,
                        properties: { 'Component Name': 'SigningRequestDetails', 'Method Name': 'cancelSigningRequest', },
                    });
                }, JSON.stringify({}));
            }
            return [2 /*return*/];
        });
    }); };
    var onRejectReasonUpdated = function (ev, rejectReason) {
        setRejectReason(rejectReason);
    };
    var validateRejectRequest = function () {
        var errorObj = {
            isPassed: false,
            errorMessage: ''
        };
        if (rejectReason == "" || rejectReason == null) {
            errorObj.errorMessage = "Please provide reject reason";
            errorObj.isPassed = false;
            return errorObj;
        }
        if (requestDetails[0].statusCode != 'AwaitingSponsorApproval' && requestDetails[0].statusCode != 'AwaitingApproval') {
            errorObj.errorMessage = "Requst is not in AwaitingSponsorApproval or AwaitingApproval";
            errorObj.isPassed = false;
            return errorObj;
        }
        errorObj.isPassed = true;
        return errorObj;
    };
    var SetAddtionalValidationsToCancelButtonInSigningReqDetailsPage = function (response) {
        if (loggedInUser != response.requestorEmail) {
            setDisableButtonCancel(true);
        }
    };
    var SetControlsFromRequestStatusInSigningReqDetailsPage = function (response) {
        var statusCode = response.statusCode;
        switch (statusCode) {
            case "Fulfilled":
                {
                    var approverMsg_1 = (response.signingType == null || response.signingType == '')
                        ? "This request has already been approved by ".concat(response.approver, " and signed Software/Firmware file has been uploaded by ").concat(response.uploadedBy, ".")
                        : "This request has already been fulfilled by ".concat(response.approver);
                    setapproverMsg(approverMsg_1);
                    setDisableButtonApprove(true);
                    setDisableButtonReject(true);
                    setDisableButtonResign(true);
                    setDisableButtonCancel(true);
                    break;
                }
            case "Approved":
                {
                    setapproverMsg("This request has already been approved by ".concat(response.approver));
                    setHideButtonApprove(true);
                    setHideButtonReject(true);
                    setHideButtonResign(true);
                    setDisableButtonCancel(true);
                    break;
                }
            case "Failed":
                {
                    if (response.approver == null || response.approver == '') {
                        setapproverMsg("Please approve or reject the request.");
                    }
                    else {
                        if (response.signingType == null || response.signingType == '') {
                            setapproverMsg("This request has already been approved by ".concat(response.approver, ", but fulfillment failed. Please, try uploading file again. Error Message: ").concat(response.infoMessage));
                            setHideButtonApprove(true);
                            setHideButtonReject(true);
                            setHideButtonCompleteSigning(false);
                            setHideButtonResign(false);
                            setDisableButtonCancel(true);
                        }
                        else {
                            setapproverMsg("This request has already been approved by ".concat(response.approver, ", but failed to sign. You can click resign to try signing again. Error Message: ").concat(response.infoMessage));
                            setDisableButtonResign(false);
                            setDisableButtonApprove(true);
                            setDisableButtonReject(true);
                            setDisableButtonCancel(true);
                        }
                    }
                    break;
                }
            case "MarkedSponsorRejected":
            case "MarkedSponsorRejectedProcessing":
            case "SponsorRejected":
            case "MarkedApproverRejected":
            case "MarkedApproverRejectedProcessing":
            case "ApproverRejected":
                {
                    var approverMsg_2 = (response.requestState == "MarkedSponsorRejected" || response.requestState == "MarkedSponsorRejectedProcessing"
                        || response.requestState == "SponsorRejected")
                        ? "This request was rejected by sponsor. Sponsor: ".concat(response.sponsor)
                        : "This request has already been rejected by ".concat(response.approver);
                    setapproverMsg(approverMsg_2);
                    setDisableButtonApprove(true);
                    setDisableButtonReject(true);
                    setDisableButtonCancel(true);
                    break;
                }
            case "Cancelled":
                {
                    setapproverMsg("The request has been cancelled by requestor.");
                    setDisableButtonApprove(true);
                    setDisableButtonReject(true);
                    setDisableButtonCancel(true);
                    break;
                }
            case "Processing":
                {
                    setapproverMsg("Request is being signed by signing service.");
                    setDisableButtonApprove(true);
                    setDisableButtonReject(true);
                    setDisableButtonCancel(true);
                    break;
                }
            case "Initialized":
                {
                    setapproverMsg("The request has been received. You will be notified as it gets processed.");
                    setDisableButtonApprove(true);
                    setDisableButtonReject(true);
                    setDisableButtonCancel(false);
                    break;
                }
            case "InitializedProcessing":
            case "SponsorApproved":
            case "SponsorApprovedProcessing":
            case "SignatureUploadedProcessing":
                {
                    setapproverMsg("Request is being processed.");
                    setDisableButtonApprove(true);
                    setDisableButtonReject(true);
                    setDisableButtonCancel(true);
                    break;
                }
            case "AwaitingSignatureUpload":
                {
                    setapproverMsg("The fulfillment is waiting on manual signing. Once the signed file is uploaded a notification will be sent.");
                    setHideButtonApprove(true);
                    setHideButtonReject(true);
                    setDisableButtonCancel(true);
                    setHideButtonCompleteSigning(false);
                    break;
                }
            case "SignatureUploaded":
                {
                    setapproverMsg("The signature file has been uploaded. Notification will be sent as the request gets fulfilled.");
                    setDisableButtonApprove(true);
                    setDisableButtonReject(true);
                    setDisableButtonCancel(true);
                    break;
                }
            case "Purged":
                {
                    setapproverMsg("Request Input and Output files have been removed and replaced with the hash of these files.");
                    setDisableButtonApprove(true);
                    setDisableButtonReject(true);
                    setDisableButtonCancel(true);
                    break;
                }
            case "AwaitingSponsorApproval":
                {
                    setapproverMsg("Request waiting for Sponsor Approval.");
                    setDisableButtonResign(true);
                    setDisableButtonReject(false);
                    setDisableButtonApprove(false);
                    setDisableButtonCancel(false);
                }
            case "AwaitingApproval":
                {
                    setapproverMsg("Request waiting for Approval.");
                    setDisableButtonResign(true);
                    setDisableButtonReject(false);
                    setDisableButtonApprove(false);
                    setDisableButtonCancel(false);
                }
            default:
                // {
                //     // this.RejectReasonArea.Visible = true;
                //     DateTime minCreatedDate = DateTime.UtcNow.AddDays(-7);
                //     if (this.listedUserNames?.Any() ?? false)
                //     {
                //         numberOfPendingRequest = this.factory.GetPendingRequestsCountForApprover(this.listedUserNames);
                //         if (numberOfPendingRequest > 0 && this.request.RequestTime >= minCreatedDate)
                //         {
                //             numberOfPendingRequest--;
                //         }
                //     }
                //     break;
                // }
                console.log("Pending to verify");
        }
    };
    var navigateToHomePage = function () {
        history.push("/dashboard");
    };
    var SearchResults = function (searchId) { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            if (searchId != "" && loggedInUser != "" && acccessToken != "") {
                url = "".concat(baseUrl, "/signing-approvals/GetSigningRequestDetails?requestId=").concat(searchId);
                httpGet(_httpClient, acccessToken, url, function (response) {
                    setRequestDetails(response.data);
                    setDefaultRequestDetails(response.data);
                    if (response.data[0].filesToSign != "") {
                        setHideFileControls(false);
                    }
                    if (response.data[0].statusCode == "Fulfilled") {
                        setHideSignedFileControls(false);
                    }
                    if (response.data[0].statusCode == "SponsorRejected" || response.data[0].statusCode == "ApproverRejected") {
                        setHideRejectReasonControls(false);
                    }
                    SetControlsFromRequestStatusInSigningReqDetailsPage(response.data[0]);
                    SetAddtionalValidationsToCancelButtonInSigningReqDetailsPage(response.data[0]);
                }, function (error) {
                    toast.error("RequestID: ".concat(searchId, " not found"));
                });
            }
            return [2 /*return*/];
        });
    }); };
    var navigateToSigningRequestDetailsPage = function (requestId) {
        history.push("/signing-request-details/".concat(requestId));
    };
    var onClickGetDataHandler = function (event) {
        if (event.target.value.length > 0) {
            setRequestId(event.target.value);
            navigateToSigningRequestDetailsPage(event.target.value);
        }
        else {
            toast.error("Please ensure Proper search criteria in Searchbox");
        }
    };
    React.useEffect(function () {
        EventEmitter.subscribe(EventType.onClick, onClickGetDataHandler);
        return function cleanup() {
            EventEmitter.unsubscribe(EventType.onClick, onClickGetDataHandler);
        };
    });
    return (React.createElement(Styled.Container, null,
        React.createElement("div", null,
            React.createElement(Stack, null,
                React.createElement(Breadcrumb, { items: itemsWithHref, maxDisplayedItems: 3, ariaLabel: "Create Items", overflowAriaLabel: "More links", style: { backgroundColor: '#FFFFFF' } }))),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Text, { variant: 'medium', block: true },
                " ",
                React.createElement("strong", null, "RequestID: \u2003"),
                requestDetails[0].requestID)),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Text, { variant: 'medium', block: true },
                " ",
                React.createElement("strong", null, "Requestor: \u2003"),
                " ",
                requestDetails[0].requestor)),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Text, { variant: 'medium', block: true },
                " ",
                React.createElement("strong", null, "Sponsor(s): \u2003"),
                " ",
                requestDetails[0].sponsors.toString())),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Text, { variant: 'medium', block: true },
                " ",
                React.createElement("strong", null, "Request Date Time (UTC): \u2003"),
                " ",
                requestDetails[0].requestDateTime && new Date(requestDetails[0].requestDateTime).toLocaleString())),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Text, { variant: 'medium', block: true },
                " ",
                React.createElement("strong", null, "Business Justification: \u2003"),
                " ",
                requestDetails[0].businessJustification)),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Text, { variant: 'medium', block: true },
                " ",
                React.createElement("strong", null, "Product: \u2003"),
                " ",
                requestDetails[0].productName)),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Text, { nowrap: true, variant: 'medium', block: true },
                " ",
                React.createElement("strong", null, "Signing Type: \u2003"),
                " ",
                requestDetails[0].signingType)),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Text, { nowrap: true, variant: 'medium', block: true },
                " ",
                React.createElement("strong", null, "Signing Detail: \u2003"),
                " ",
                requestDetails[0].signingDetail)),
        requestDetails[0].serialNumber && React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Text, { nowrap: true, variant: 'medium', block: true },
                " ",
                React.createElement("strong", null, "Serial Number: \u2003"),
                " ",
                requestDetails[0].serialNumber)),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Text, { variant: 'medium', block: true },
                " ",
                React.createElement("strong", null, "Software/Firmware Version: \u2003"),
                " ",
                requestDetails[0].firmwareVersion)),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Text, { variant: 'medium', block: true },
                " ",
                React.createElement("strong", null, "Software/Firmware Description: \u2003"),
                " ",
                requestDetails[0].firmwareDescription)),
        !hideFileControls && requestDetails[0].filesToSign && (React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Text, { variant: 'medium', block: true },
                " ",
                React.createElement("strong", null, "File To Sign: \u2003"),
                " ",
                requestDetails[0].filesToSign),
            React.createElement("div", null,
                React.createElement(TooltipHost
                // content={requestDetails[0].fileInfoStr}
                , { 
                    // content={requestDetails[0].fileInfoStr}
                    content: defaultRequestBlobMetadata, id: tooltipId, calloutProps: calloutProps, styles: hostStyles },
                    React.createElement(IconButton, { title: 'FileProps', iconProps: { iconName: 'Info' }, "aria-describedby": tooltipId }))),
            React.createElement(DownloadFilesWithPrimaryButton, { requestId: requestDetails[0].requestID, blobPrefix: "FilesToSign/", text: "Input Files", fileTypeId: "FILE_TO_SIGN" }))),
        !hideFileControls && requestDetails[0].smokeTestFiles && (React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Text, { variant: 'medium', block: true },
                " ",
                React.createElement("strong", null, " Smoke Test File: \u2003"),
                requestDetails[0].smokeTestFiles),
            React.createElement(DownloadFilesWithPrimaryButton, { requestId: requestDetails[0].requestID, blobPrefix: "SmokeTestResult/", text: "SmokeTest Files", fileTypeId: "SMOKE_TEST_RESULT" }))),
        !hideSignedFileControls && requestDetails[0].signatureFiles && (React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Text, { variant: 'medium', block: true },
                " ",
                React.createElement("strong", null, " Signature File: \u2003"),
                "  ",
                requestDetails[0].signatureFiles),
            React.createElement(DownloadFilesWithPrimaryButton, { requestId: requestDetails[0].requestID, blobPrefix: "SignedFiles/", text: "Signature Files", fileTypeId: "SIGNED_FILE" }))),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Text, { variant: 'medium', block: true },
                " ",
                React.createElement("strong", null, "Signing Request Status: \u2003"),
                requestDetails[0].statusCode)),
        !hideRejectReasonControls && (React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Text, { variant: 'medium', block: true },
                " ",
                React.createElement("strong", null, " Request Reject Reasson: \u2003"),
                requestDetails[0].rejectReason))),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(PrimaryButton, { hidden: hideButtonApprove, disabled: disableButtonApprove, secondaryText: "Approve Request", onClick: toggleHideApproveReqDialog, text: "Approve Request" }),
            React.createElement(Dialog, { hidden: hideApproveReqDialog, onDismiss: toggleHideApproveReqDialog, dialogContentProps: approveDialogContentProps, modalProps: modalProps, minWidth: 525 },
                React.createElement(DialogFooter, null,
                    React.createElement(PrimaryButton, { onClick: function () {
                            {
                                toggleHideApproveReqDialog;
                            }
                            RequestAction("Approve");
                        }, text: "Approve" }),
                    React.createElement(DefaultButton, { onClick: toggleHideApproveReqDialog, text: "Cancel" }))),
            React.createElement(PrimaryButton, { disabled: disableButtonReject, hidden: hideButtonReject, secondaryText: "Reject Request", onClick: toggleHideRejectReqDialog, text: "Reject Request" }),
            React.createElement(Dialog, { hidden: hideRejectReqDialog, onDismiss: toggleHideRejectReqDialog, dialogContentProps: rejectDialogContentProps, modalProps: modalProps, minWidth: 525 },
                React.createElement(DialogFooter, null,
                    React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
                        React.createElement(TextField, { label: "Reason for rejection: ", required: true, multiline: true, rows: (4), style: { width: 450 }, onChange: function (e, text) { return onRejectReasonUpdated(e, text); } })),
                    React.createElement(PrimaryButton, { onClick: function () {
                            {
                                toggleHideRejectReqDialog;
                            }
                            RequestAction("Reject");
                        }, text: "Reject" }),
                    React.createElement(DefaultButton, { onClick: toggleHideRejectReqDialog, text: "Cancel" }))),
            React.createElement(PrimaryButton, { hidden: hideButtonResign, disabled: disableButtonResign, secondaryText: "Re-Sign Request", onClick: toggleHideReSignReqDialog, text: "Re-Sign Request" }),
            React.createElement(Dialog, { hidden: hideReSignReqDialog, onDismiss: toggleHideReSignReqDialog, dialogContentProps: resignDialogContentProps, modalProps: modalProps, minWidth: 525 },
                React.createElement(DialogFooter, null,
                    React.createElement(PrimaryButton, { onClick: function () {
                            RequestAction("Approve");
                            {
                                toggleHideReSignReqDialog;
                            }
                        }, text: "Re-Sign" }),
                    React.createElement(DefaultButton, { onClick: toggleHideReSignReqDialog, text: "Don't Re-Sign" }))),
            React.createElement(PrimaryButton, { hidden: hideButtonCancel, disabled: disableButtonCancel, secondaryText: "Cancel Request", onClick: toggleHideCancelReqDialog, text: "Cancel Request" }),
            React.createElement(Dialog, { hidden: hideCancelReqDialog, onDismiss: toggleHideCancelReqDialog, dialogContentProps: cancelDialogContentProps, modalProps: modalProps, minWidth: 525 },
                React.createElement(DialogFooter, null,
                    React.createElement(PrimaryButton, { onClick: function () {
                            {
                                toggleHideCancelReqDialog;
                            }
                            RequestAction("Okay, Cancel Request");
                        }, text: "Cancel" }),
                    React.createElement(DefaultButton, { onClick: toggleHideCancelReqDialog, text: "Don't Cancel" }))))));
};
var connected = withContext(withRouter(SigningRequestDetails));
export { connected as SigningRequestDetails };
