import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AdvancedSearch } from '../src/Containers/ManageSigning/AdvancedSearch';
import { Dashboard } from '../src/Containers/Dashboard/Dashboard';
import ViewMyRequests from './Containers/ManageSigning/MyRequests';
import ViewMyApprovals from './Containers/ManageSigning/MyApprovals';
import { MyRequestDetails } from './Containers/ManageSigning/MyRequestDetails';
import { ApproveRequestDetails } from './Containers/ManageSigning/ApproveRequestDetails';
import { SigningRequestDetails } from './Containers/ManageSigning/SigningRequestDetails';
import { CreateSigningRequest } from './Containers/SubmitSigning/CreateSigningRequest';
export function Routes() {
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: "/dashboard", component: Dashboard }),
        React.createElement(Route, { path: "/advanced-search", component: AdvancedSearch }),
        React.createElement(Route, { path: "/submit-signing-request", component: CreateSigningRequest }),
        React.createElement(Route, { path: "/view-my-approvals", component: ViewMyApprovals }),
        React.createElement(Route, { path: "/view-my-requests", component: ViewMyRequests }),
        React.createElement(Route, { path: "/my-request-details", component: MyRequestDetails }),
        React.createElement(Route, { path: "/approve-request-details", component: ApproveRequestDetails }),
        React.createElement(Route, { path: "/signing-request-details", component: SigningRequestDetails }),
        React.createElement(Route, { path: "/", component: Dashboard })));
}
