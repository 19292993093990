export var Initial_Sign_Request_Display = {
    enableProduct: true,
    displaySigningTypeCode: false,
    displaySigningType: false,
    displaySerialNumber: false,
    displayBusinessJustification: false,
    displayFilesToSign: false,
    displayArtifactVersion: false,
    displayArtifactDescription: false,
    displaySmokeTestResult: false,
    displayEmailCc: false,
    displaySubmitButton: false
};
export var displaySignFormReducer = function (state, action) {
    var signingTypes = ['(FW)', '(Codesign)', '(ESRP)'];
    switch (action.type) {
        case 'onProductSelect':
            {
                if (action.payload.value.localeCompare('Future Product', undefined, { sensitivity: 'base' }) === 0) {
                    return {
                        enableProduct: true,
                        displaySigningTypeCode: true,
                        displaySigningType: false,
                        displaySerialNumber: false,
                        displayBusinessJustification: false,
                        displayFilesToSign: false,
                        displayArtifactVersion: false,
                        displayArtifactDescription: false,
                        displaySmokeTestResult: false,
                        displayEmailCc: false,
                        displaySubmitButton: false
                    };
                }
                else {
                    return {
                        enableProduct: true,
                        displaySigningTypeCode: false,
                        displaySigningType: true,
                        displaySerialNumber: false,
                        displayBusinessJustification: false,
                        displayFilesToSign: false,
                        displayArtifactVersion: false,
                        displayArtifactDescription: false,
                        displaySmokeTestResult: false,
                        displayEmailCc: false,
                        displaySubmitButton: false
                    };
                }
            }
        case 'onSigningTypeCodeValid':
            {
                if (contains(signingTypes, action.payload.value)) {
                    return {
                        enableProduct: true,
                        displaySigningTypeCode: true,
                        displaySigningType: false,
                        displaySerialNumber: false,
                        displayBusinessJustification: true,
                        displayFilesToSign: true,
                        displayArtifactVersion: true,
                        displayArtifactDescription: true,
                        displaySmokeTestResult: true,
                        displayEmailCc: true,
                        displaySubmitButton: true
                    };
                }
                else {
                    return {
                        enableProduct: true,
                        displaySigningTypeCode: true,
                        displaySigningType: false,
                        displaySerialNumber: true,
                        displayBusinessJustification: true,
                        displayFilesToSign: true,
                        displayArtifactVersion: false,
                        displayArtifactDescription: false,
                        displaySmokeTestResult: false,
                        displayEmailCc: true,
                        displaySubmitButton: true
                    };
                }
            }
        case 'onSigningTypeSelect':
            {
                if (contains(signingTypes, action.payload.value)) {
                    return {
                        enableProduct: true,
                        displaySigningTypeCode: false,
                        displaySigningType: true,
                        displaySerialNumber: false,
                        displayBusinessJustification: true,
                        displayFilesToSign: true,
                        displayArtifactVersion: true,
                        displayArtifactDescription: true,
                        displaySmokeTestResult: true,
                        displayEmailCc: true,
                        displaySubmitButton: true
                    };
                }
                else {
                    return {
                        enableProduct: true,
                        displaySigningTypeCode: false,
                        displaySigningType: true,
                        displaySerialNumber: true,
                        displayBusinessJustification: true,
                        displayFilesToSign: true,
                        displayArtifactVersion: false,
                        displayArtifactDescription: false,
                        displaySmokeTestResult: false,
                        displayEmailCc: true,
                        displaySubmitButton: true
                    };
                }
            }
        default: return state;
    }
};
export var contains = function (arr, searchStr) {
    return arr.some(function (e) { return searchStr.includes(e); });
};
