var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ProgressIndicator } from "@fluentui/react";
import moment from "moment";
import React from "react";
import { useState } from "react";
var NotificationContext = React.createContext({
    items: [],
    headerBody: undefined,
    updateItem: function (key, dStatus, rStatus) { },
    success: function (key, text) { },
    error: function (key, text) { },
    inProgress: function (key, text) { }
});
var NotificationProvider = function (props) {
    var _a = useState([]), items = _a[0], setItems = _a[1];
    var _b = useState(), headerBody = _b[0], setHeaderBody = _b[1];
    var success = function (key, text) {
        setHeaderBody(undefined);
        setItems(__spreadArray(__spreadArray([], items, true), [{ itemKey: key, status: 'unread', subjectHeader: text, timeDelivered: moment(), subjectIcon: 'Accept' }], false));
    };
    var error = function (key, text) {
        setHeaderBody(undefined);
        setItems(__spreadArray(__spreadArray([], items, true), [{ itemKey: key, status: 'unread', subjectHeader: text, timeDelivered: moment(), subjectIcon: 'Error' }], false));
    };
    var inProgress = function (key, text) {
        setHeaderBody(React.createElement(ProgressIndicator, null));
        setItems(__spreadArray(__spreadArray([], items, true), [{ itemKey: key, status: 'unread', subjectHeader: text, timeDelivered: moment(), subjectIcon: 'Upload' }], false));
    };
    var updateItem = function (itemKey, dStatus, rStatus) {
        if (rStatus) {
            var item = items.find(function (i) { return i.itemKey == itemKey; });
            if (item) {
                item.status = 'read';
                setItems(__spreadArray(__spreadArray([], items.filter(function (i) { return i.itemKey != (item === null || item === void 0 ? void 0 : item.itemKey); }), true), [__assign({}, item)], false));
            }
        }
        if (dStatus === 'dismiss') {
            setItems(items.filter(function (i) { return i.itemKey != itemKey; }));
        }
    };
    return (React.createElement(NotificationContext.Provider, { value: {
            items: items,
            success: success,
            error: error,
            headerBody: headerBody,
            inProgress: inProgress,
            updateItem: updateItem
        } }, props.children));
};
export { NotificationProvider };
export default NotificationContext;
