export var navConfig = [
    {
        key: 'Main',
        links: [
            {
                key: 1,
                name: 'Dashboard',
                href: '/dashboard',
                icon: 'Home',
            },
            {
                key: 2,
                name: 'Manage Signing Requests',
                icon: 'ViewDashboard',
                links: [
                    // {
                    //     key: 1,
                    //     name: 'Advanced Search',
                    //     href: '/advanced-search',
                    //     icon: 'Search'
                    // },
                    // {
                    //     key: 2,
                    //     name: 'Submit Signing Request',
                    //     href: '/submit-signing-request',
                    //     icon: 'CircleAddition'
                    // },
                    {
                        key: 3,
                        name: 'My Approvals',
                        href: '/view-my-approvals',
                        icon: 'WaitlistConfirm'
                    },
                    {
                        key: 4,
                        name: 'My Requests',
                        href: '/view-my-requests',
                        icon: 'PageList'
                    },
                    {
                        key: 5,
                        name: 'New Request',
                        href: '/submit-signing-request',
                        icon: 'Add'
                    },
                ],
            },
            // {
            //     key: 5,
            //     name: 'Manage Administration',
            //     href: '/manage-signing-data',
            //     icon: 'Admin'
            // },
        ],
    },
];
