import { DefaultPalette, mergeStyles, mergeStyleSets } from "@fluentui/react";
var statusIconCommnonStyles = mergeStyles({
    fontSize: 10,
    height: 15,
    width: 15,
});
var statusIconStyles = mergeStyleSets({
    completedIcon: [{ color: 'green' }, statusIconCommnonStyles],
    failedIcon: [{ color: 'red' }, statusIconCommnonStyles]
});
var stackStyles = { root: { width: 500 } };
var tabLeftStackTokens = {
    childrenGap: 10,
    padding: 0,
};
var tabRighStackTokens = {
    childrenGap: 's2',
    padding: 0,
};
var stackItemHeadingStyles = {
    root: {
        alignItems: 'left',
        //background: DefaultPalette.themePrimary,
        color: DefaultPalette.black,
        display: 'flex',
        height: 50,
        justifyContent: 'left',
        marginTop: 10
    },
};
var stackItemRefreshStyles = {
    root: {
        alignItems: 'right',
        //background: DefaultPalette.t,
        color: DefaultPalette.black,
        display: 'flex',
        height: 50,
        width: 50,
        justifyContent: 'right',
        paddingRight: 5,
        marginTop: 10,
        marginRight: 12,
        marginBottom: 0
    },
};
var stackItemRequestHeaderStyles = {
    root: {
        width: '15%',
        padding: 5,
        margin: 5,
        marginTop: 5,
        wordWrap: 'break-word'
    },
};
var stackItemRequestContentStyles = {
    root: {
        width: '85%',
        padding: 5,
        margin: 5,
        marginTop: 5,
        wordWrap: 'break-word'
    },
};
var stackItemRequestHeaderStylesInPanel = {
    root: {
        width: '40%',
        padding: 5,
        margin: 5,
        marginTop: 5,
        wordWrap: 'break-word'
    },
};
var stackItemRequestContentStylesInPanel = {
    root: {
        width: '60%',
        padding: 5,
        margin: 5,
        marginTop: 5,
        wordWrap: 'break-word'
    },
};
var stackItemSearchBoxStyles = {
    root: {
        alignItems: 'left',
        color: DefaultPalette.black,
        display: 'flex',
        height: 30,
        justifyContent: 'left',
        marginTop: 10
    },
};
export { statusIconStyles, statusIconCommnonStyles, stackStyles, tabLeftStackTokens, tabRighStackTokens, stackItemHeadingStyles, stackItemRefreshStyles, stackItemRequestHeaderStyles, stackItemRequestContentStyles, stackItemRequestHeaderStylesInPanel, stackItemRequestContentStylesInPanel, stackItemSearchBoxStyles };
