import * as React from 'react';
import { Text } from '@fluentui/react/lib/Text';
import * as Styled from '../../shared/layout';
import { PrimaryButton, TextField, SelectionMode, Selection, } from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { Stack } from '@fluentui/react/lib/Stack';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
var EventEmitter = require('../CommonUtilities/EventEmitter.js').EventEmitter;
var EventType = require('../CommonUtilities/EventEmitter.js').EventType;
import { useState, useEffect, useMemo } from 'react';
import '!style-loader!css-loader!react-toastify/dist/ReactToastify.css';
import { ComponentContext } from '@employee-experience/common';
import { httpGet } from '../../shared/APICalls';
var appConfig = require('../../Config/appConfig.js').appConfig;
var baseUrl = appConfig.AppSettings.APIUrl;
var CreateSigningRequest = function (props) {
    var resourceId = appConfig.AppSettings.AppClientId;
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var _a = React.useState(false), isValidationPassed = _a[0], setIsValidationPassed = _a[1];
    var _b = React.useState({}), createSigningRequestData = _b[0], setCreateSigningRequestData = _b[1];
    var _c = React.useState([]), prouctDetails = _c[0], setProuctDetails = _c[1];
    var _d = useState(''), productName = _d[0], setProductName = _d[1];
    var _e = React.useState([]), signingDetail = _e[0], setSigningDetail = _e[1];
    var _f = useState(''), signingTypeName = _f[0], setSigningTypeName = _f[1];
    var _g = React.useState([]), signingTypeOptions = _g[0], setSigningTypeOptions = _g[1];
    var _h = useState(sessionStorage.getItem('signingType') || 'Test Playready Signer (FW)'), signingType = _h[0], setSigningType = _h[1];
    var selectedProductNameRef = React.useRef('');
    var _j = useState({
        requestor: '',
        fteSponsor: '',
        productName: '',
        signingType: '',
    }), errors = _j[0], setErrors = _j[1];
    var dropdownStyles = {
        dropdown: { width: 300 },
    };
    var textFieldStyles = { fieldGroup: {} };
    var textField = { width: 300 };
    var formFieldStyle = {
        marginLeft: '15%',
        marginTop: 5,
        marginRight: '20%',
        marginBottom: 18,
    };
    var buttonFieldStyle = {
        width: 200,
        marginLeft: '50%',
        marginRight: '50%',
    };
    var searchBoxStyle = {
        marginTop: '10px',
    };
    var verticalGapStackTokens = {
        childrenGap: 15,
        padding: 10,
    };
    var controlClass = mergeStyleSets({
        control: {
            marginBottom: '10px',
            marginRight: '30px',
            maxWidth: '300px',
        },
        searchStyles: {
            marginTop: '30px',
        },
        submitStyles: {
            marginTop: '20px',
            marginLeft: '10px',
            marginRight: '10px',
            maxWidth: '300px',
        },
    });
    var stackStyles = { root: { width: 500 } };
    var horizontalGapStackTokens = {
        childrenGap: 10,
        padding: 10,
    };
    var inputProps = {
        'aria-label': 'Tag Picker',
    };
    var stackTokens = { childrenGap: 10 };
    var _k = useState([]), selectedIndices = _k[0], setSelectedIndices = _k[1];
    var _l = useState(), selectedItems = _l[0], setSelectedItems = _l[1];
    var selection;
    selection = useMemo(function () {
        return new Selection({
            onSelectionChanged: function () {
                setSelectedItems(selection.getSelection());
                setSelectedIndices(selection.getSelectedIndices());
            },
            selectionMode: SelectionMode.multiple,
        });
    }, []);
    var _m = React.useContext(ComponentContext), telemetryClient = _m.telemetryClient, authClient = _m.authClient;
    var _o = React.useState(''), loggedInUser = _o[0], setLoggedInUser = _o[1];
    var _p = useState(''), acccessToken = _p[0], setAcccessToken = _p[1];
    useEffect(function () {
        authClient.getUserId().then(function (res) {
            setLoggedInUser(res);
        });
        authClient.acquireToken(__CLIENT_API_SCOPES__).then(function (res) {
            setAcccessToken(res);
        });
    });
    useEffect(function () {
        if (loggedInUser != "" && acccessToken != "") {
            var url = "".concat(baseUrl, "/Signing/GetAllProducts");
            httpGet(_httpClient, acccessToken, url, function (response) {
                setProuctDetails(response.data.map(function (name) { return ({ key: name.productId, text: name.productName }); }));
            }, function (error) {
                //log to App Insights
            });
        }
    }, []);
    useEffect(function () {
        if (loggedInUser != "" && acccessToken != "") {
            var url = "".concat(baseUrl, "/Signing/GetSigningDetail?productId=1");
            httpGet(_httpClient, url, acccessToken, function (response) {
                setSigningTypeOptions(response.data.map(function (name) { return ({ key: name.signingType, text: name.signingDetail }); }));
            }, function (error) {
                //log to App Insights
            });
        }
    }, []);
    var onSubmit = function (e) { };
    var validationsWhileLinkPOsShipments = function () {
        var errorObj = {
            isPassed: false,
            errorMessage: ''
        };
    };
    var setProductNameRef = function (value) {
        selectedProductNameRef.current = value;
    };
    var clearProductNameRef = function () {
        selectedProductNameRef.current = '';
    };
    var getProductNameRef = function () {
        return selectedProductNameRef.current;
    };
    var onSelectOfProductName = function (event, item) {
        setProductNameRef(item.text);
        setProductName(item.text);
    };
    var onSelectSigningType = function (event, item) {
        setSigningType(item.text);
    };
    return (React.createElement(Styled.listContainer, null,
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Text, { variant: 'large', block: true },
                React.createElement("strong", null, "Requestor: "),
                props.requestorAlias)),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(TextField, { name: "fteSponsor", id: "fteSponsor", label: "FTE Sponsor", style: textField, validateOnLoad: false, validateOnFocusOut: true, 
                //onChange={e => updateFormData(e)}
                required: true })),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Dropdown, { placeholder: "Select the Product", label: "Product", options: prouctDetails, 
                // disabled={getDisableCompanyCodeRef()}
                styles: dropdownStyles, selectedKey: productName, onChange: onSelectOfProductName })),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Dropdown, { placeholder: "Select the Signing Type", label: "Signing Type:", options: signingTypeOptions, styles: dropdownStyles, selectedKey: signingType, onChange: onSelectSigningType })),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(TextField, { name: "businessJustification", id: "businessJustification", label: "BusinessJustification", style: textField, multiline: true, 
                //value={formData.businessJustification}
                validateOnLoad: false, 
                //onGetErrorMessage={handleFormValidation}
                validateOnFocusOut: true, 
                //onChange={e => updateFormData(e)}
                required: true })),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(TextField, { name: "fileToSign", id: "fileToSign", label: "File To Sign", style: textField, 
                //value={formData.fileToSign}
                validateOnLoad: false, 
                //onGetErrorMessage={handleFormValidation}
                validateOnFocusOut: true, 
                //onChange={e => updateFormData(e)}
                required: true })),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(TextField, { name: "swFwVersion", id: "swFwVersion", label: "Software/FirmwareVersion", style: textField, 
                //value={formData.swFwVersion}
                validateOnLoad: false, 
                //onGetErrorMessage={handleFormValidation}
                validateOnFocusOut: true, 
                //onChange={e => updateFormData(e)}
                required: true })),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(TextField, { name: "swFwDescription", id: "swFwDescription", label: "Software/Firmware Description", style: textField, 
                //value={formData.swFwDescription}
                validateOnLoad: false, 
                //onGetErrorMessage={handleFormValidation}
                validateOnFocusOut: true, 
                //onChange={e => updateFormData(e)}
                required: true })),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(TextField, { name: "SmokeTestResult", id: "smokeTestResult", label: "Smoke Test Result", style: textField, 
                //value={formData.smokeTestResult}
                validateOnLoad: false, 
                //onGetErrorMessage={handleFormValidation}
                validateOnFocusOut: true, 
                //onChange={e => updateFormData(e)}
                required: true })),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(TextField, { name: "EmailCC", id: "emailCC", label: "Email CC", style: textField, 
                //value={formData.emailCC}
                validateOnLoad: false, 
                //onGetErrorMessage={handleFormValidation}
                validateOnFocusOut: true, 
                //onChange={e => updateFormData(e)}
                required: true })),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(PrimaryButton, { type: "submit", text: "Create Signing Request", 
                //style={buttonFieldStyle}
                className: controlClass.submitStyles, onClick: function (event) {
                    EventEmitter.dispatch(EventType.onClick, event);
                }, allowDisabledFocus: true }))));
};
export default CreateSigningRequest;
