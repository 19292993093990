import { ComponentContext } from "@employee-experience/common";
import { Label, NormalPeoplePicker, ValidationState } from "@fluentui/react";
import React from "react";
import { useRef } from "react";
import { getUsersFromGraph } from "../../Services/GraphApiService";
var errorMessageStyles = {
    root: { color: 'rgb(164, 38, 44)', fontWeight: 400, fontFamily: 'Segoe UI', fontSize: '12px' }
};
var userPickerStyles = {
    root: { width: 300, backgroundColor: 'white' },
    text: undefined,
    itemsWrapper: undefined,
    input: undefined,
    screenReaderText: undefined
};
var userPickerValidationStyles = {
    root: { width: 300, backgroundColor: 'white', border: '1px solid red' },
    text: undefined,
    itemsWrapper: undefined,
    input: undefined,
    screenReaderText: undefined
};
var suggestionProps = {
    suggestionsHeaderText: 'Suggested People',
    mostRecentlyUsedHeaderText: 'Suggested Contacts',
    noResultsFoundText: 'No results found',
    loadingText: 'Loading',
    showRemoveButtons: true,
    suggestionsAvailableAlertText: 'People Picker Suggestions available',
    suggestionsContainerAriaLabel: 'Suggested contacts',
};
export function UserPicker(props) {
    var _a = React.useContext(ComponentContext), telemetryClient = _a.telemetryClient, authClient = _a.authClient;
    var picker = useRef(null);
    var onFilterChanged = function (filterText, currentPersonas) {
        if (filterText) {
            return getPersonas(authClient, filterText);
        }
        else {
            return [];
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement(NormalPeoplePicker, { onResolveSuggestions: onFilterChanged, 
                // eslint-disable-next-line react/jsx-no-bind
                getTextFromItem: getTextFromItem, pickerSuggestionsProps: suggestionProps, className: 'ms-PeoplePicker', key: 'normal', 
                // eslint-disable-next-line react/jsx-no-bind
                onValidateInput: validateInput, removeButtonAriaLabel: 'Remove', inputProps: {
                    'aria-label': 'People Picker',
                }, onChange: function (i) { return props.onChange(i === null || i === void 0 ? void 0 : i.map(function (i) { return i.secondaryText; })); }, componentRef: picker, onInputChange: onInputChange, styles: props.validationMessage ? userPickerValidationStyles : userPickerStyles }),
            React.createElement(Label, { styles: errorMessageStyles }, props.validationMessage))));
}
function getPersonas(authClient, searchBy) {
    return getUsersFromGraph(authClient, searchBy).then(function (res) {
        return res.data.value.map(function (r) {
            var per = { text: r.displayName, secondaryText: r.userPrincipalName };
            return per;
        });
    });
}
function getTextFromItem(persona) {
    return persona.text;
}
function validateInput(input) {
    if (input.indexOf('@') !== -1) {
        return ValidationState.valid;
    }
    else if (input.length > 1) {
        return ValidationState.invalid;
    }
    else {
        return ValidationState.invalid;
    }
}
function onInputChange(input) {
    var outlookRegEx = /<.*>/g;
    var emailAddress = outlookRegEx.exec(input);
    if (emailAddress && emailAddress[0]) {
        return emailAddress[0].substring(1, emailAddress[0].length - 1);
    }
    return input;
}
