import * as React from 'react';
import { ComponentContext } from "@employee-experience/common/lib/ComponentContext";
import { IconButton } from '@fluentui/react';
import { appConfig } from './Config/appConfig';
var EventEmitter = require('../src/Containers/CommonUtilities/EventEmitter.js').EventEmitter;
var EventType = require('../src/Containers/CommonUtilities/EventEmitter.js').EventType;
export function useHeaderConfig(props) {
    var telemetryHook = React.useContext(ComponentContext).telemetryClient;
    return {
        headerLabel: appConfig.AppSettings.AppName,
        appNameSettings: {
            label: appConfig.AppSettings.AppName
        },
        searchSettings: {
            placeholder: 'Search Signing Requests',
            onSearch: function (term) { return EventEmitter.dispatch(EventType.onClick, event); },
        },
        farRightSettings: {
            notificationsSettings: {
                headerBody: props.headerBody,
                updateItem: props.updateItem,
                items: props.items,
                newNotifications: props.items.filter(function (i) { return i.status == 'unread'; }).length,
                telemetryHook: telemetryHook,
            },
            helpSettings: {
                body: React.createElement("div", null,
                    React.createElement("p", null, "For any queries, contact WDS HSM team "),
                    React.createElement("br", null),
                    React.createElement("a", { href: "mailto:wdshsm@microsoft.com?Subject=I have a query on AutoSign&Body=Here is my query", title: " Query on AutoSign" },
                        "WDS HSM Team",
                        React.createElement(IconButton, { onClick: function () {
                                //no action
                            }, iconProps: { iconName: 'PublicEmail' }, title: 'EMail WDS HSM Team' })),
                    React.createElement("br", null),
                    React.createElement("br", null),
                    React.createElement("a", { href: "https://www.deviceswiki.com/wiki/Signing_with_AutoSign", target: "_blank" }, "Signing with AutoSign Wiki"),
                    React.createElement("br", null),
                    React.createElement("br", null)),
                telemetryHook: telemetryHook,
            }
        },
        telemetryHook: telemetryHook
    };
}
