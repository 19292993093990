var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ComponentContext } from '@employee-experience/common';
import { PrimaryButton } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { httpGet } from "../../shared/APICalls";
function DownloadFilesWithPrimaryButton(props) {
    var _this = this;
    var _a, _b;
    var appConfig = require('../../Config/appConfig.js').appConfig;
    var baseUrl = appConfig.AppSettings.APIUrl;
    var storageAccountName = appConfig.AppSettings.StorageAccountName;
    var BlobServiceClient = require("@azure/storage-blob").BlobServiceClient;
    var _c = React.useState([]), sasTokenReadOnly = _c[0], setSasTokenReadOnly = _c[1];
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var _d = React.useContext(ComponentContext), telemetryClient = _d.telemetryClient, authClient = _d.authClient;
    var _e = React.useState(''), loggedInUser = _e[0], setLoggedInUser = _e[1];
    var _f = useState(''), acccessToken = _f[0], setAcccessToken = _f[1];
    useEffect(function () {
        authClient.getUserId().then(function (res) {
            setLoggedInUser(res);
        });
        authClient.acquireToken(__CLIENT_API_SCOPES__).then(function (res) {
            setAcccessToken(res);
        });
    });
    useEffect(function () {
        if (sasTokenReadOnly && sasTokenReadOnly.length > 0) {
            downloadAllFiles(props.requestId, props.blobPrefix);
        }
    }, [sasTokenReadOnly]);
    var GetSasByTypeAsync = function (requestId, blobPrefix) { return __awaiter(_this, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            if (props.requestId != "" && acccessToken != "") {
                url = "".concat(baseUrl, "/signing-approvals/GetSasByFileTypeAsync?requestId=").concat(props.requestId, "&fileType=").concat(props.fileTypeId);
                httpGet(_httpClient, acccessToken, url, function (response) {
                    setSasTokenReadOnly(response.data);
                }, function (error) {
                    //log to app insights
                });
            }
            return [2 /*return*/];
        });
    }); };
    var downloadAllFiles = function (requestId, blobPrefix) { return __awaiter(_this, void 0, void 0, function () {
        var filesToDownload;
        return __generator(this, function (_a) {
            filesToDownload = [];
            sasTokenReadOnly.map(function (i) {
                var url = i.split('?');
                filesToDownload = __spreadArray(__spreadArray([], filesToDownload, true), [url[0].replace(/%2F/gi, '/') + '?' + url[1]], false);
            });
            download_files(filesToDownload.length, filesToDownload);
            return [2 /*return*/];
        });
    }); };
    var download_files = function (count, filesToDownload) {
        var download_next = function (i) {
            if (i >= count) {
                return;
            }
            var downloadLink = filesToDownload[i];
            var a = document.createElement('a');
            a.href = downloadLink;
            a.target = '_blank';
            a.download = 'downloadLink';
            (document.body || document.documentElement).appendChild(a);
            if (a.click) {
                a.click(); // The click method is supported by most browsers.
            }
            else {
            }
            a.parentNode.removeChild(a);
            setTimeout(function () {
                download_next(i + 1);
            }, 500);
        };
        // Initiate the first download.
        download_next(0);
    };
    return (React.createElement(PrimaryButton, { disabled: (_a = props.disabled) !== null && _a !== void 0 ? _a : false, onClick: function (e) {
            GetSasByTypeAsync(props.requestId, props.blobPrefix);
        }, 
        //title={'Download'}
        text: (_b = props.text) !== null && _b !== void 0 ? _b : 'Download', iconProps: { iconName: 'Download' } }));
}
export default DownloadFilesWithPrimaryButton;
