import { Breadcrumb, Stack } from "@fluentui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { SignRequestForm } from "./SignRequestForm";
import * as Styled from '../../shared/layout';
export function CreateSigningRequest(props) {
    var history = useHistory();
    var navigateToHomePage = function () {
        history.push("/dashboard");
    };
    var navigateToMyRequestsPage = function () {
        history.push("/view-my-requests");
    };
    var itemsWithHref = [
        {
            text: 'Home',
            key: 'f1',
            onClick: function () {
                navigateToHomePage();
            },
        },
        {
            text: 'My Requests',
            key: 'f2',
            onClick: function () {
                navigateToMyRequestsPage();
            },
        },
        {
            text: "New Sign Request",
            key: 'f5',
            isCurrentItem: true,
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Styled.Container, null,
            React.createElement("div", null,
                React.createElement(Stack, null,
                    React.createElement(Breadcrumb, { items: itemsWithHref, maxDisplayedItems: 3, ariaLabel: "Create Items", overflowAriaLabel: "More links", style: { backgroundColor: '#FFFFFF' } }))),
            React.createElement(SignRequestForm, null))));
}
