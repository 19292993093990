// Any changes in this file requires rebuild
// const dashboardDynamicCompnoents = require('./src/Examples/Dashboard/config');
// const dynamicReduxHooksComponents = require('./src/Examples/DynamicReduxHooks/config');
// const dynamicReduxClassComponents = require('./src/Examples/DynamicReduxClass/config');
// const dynamicSubRoutesComponent = require('./src/Examples/DynamicSubRoutes/config');
// const dynamicCustomPropsComponents = require('./src/Examples/DynamicCustomProps/config');

const staticComponents = {
    /* =========================
     * Add host app entry point here
     * ====================== */
    app: './src/App.tsx',
};

const dynamicComponents = {
    'dashboard': './src/Containers/Dashboard/Dashboard.tsx'
};

const globals = {
    /* =========================
     * Add build time variables here
     * These variables also need to added in ./global.d.ts file to be available in Typescript
     * ====================== */

    __IS_DEVELOPMENT__: process.env.NODE_ENV === 'development' || true,
    __APP_NAME__: 'AutoSign',
    __APP_VERSION__: '2.0',
    __CLIENT_ID__: 'abc79d35-279d-4306-ab08-80ca2798c84b',
    __BASE_URL__: 'https://autosign.trafficmanager.net',
    __API_URL__ : 'https://autosignwebapi-prod-westus2.azurewebsites.net/api/v2.0',
    __STORAGE_ACCOUNT__ : 'autosignblobstorageprod' ,
    __INSTRUMENTATION_KEY__: 'ebada7f4-fa58-4cd7-b83c-c51fd8e204d1',
    __CLIENT_API_SCOPES__: [`https://autosign/user_impersonation`]
};
const globals_test = {
    /* =========================
     * Add build time variables here
     * These variables also need to added in ./global.d.ts file to be available in Typescript
     * ====================== */

    __IS_DEVELOPMENT__: process.env.NODE_ENV === 'development' || true,
    __APP_NAME__: 'AutoSign',
    __APP_VERSION__: '2.0',
    __CLIENT_ID__: '05ebf7b9-670c-48bd-ae3b-adac651bb1cb',
    __BASE_URL__: 'https://autosign-test-westus2.azurewebsites.net',
    __API_URL__ : 'https://autosign-testapi-eastus2.azurewebsites.net/api/v2.0',
    __STORAGE_ACCOUNT__ : 'autosigntestdiag',
    __INSTRUMENTATION_KEY__: 'b8de1d0d-1fa0-4a65-95d4-0e0df86b1cc9',
    __CLIENT_API_SCOPES__: [`http://AutoSignSecretInfoTest/user_impersonation`]
};
const globals_pme_test = {
    /* =========================
     * Add build time variables here
     * These variables also need to added in ./global.d.ts file to be available in Typescript
     * ====================== */

    __IS_DEVELOPMENT__: process.env.NODE_ENV === 'development' || true,
    __APP_NAME__: 'AutoSign',
    __APP_VERSION__: '2.0',
    __CLIENT_ID__: '8ced2975-83d6-4d39-9724-a6b665f58506',
    __BASE_URL__: 'https://test-sws-autosign-webui-westus3-azapp.azurewebsites.net/',
    __API_URL__ : 'https://autosignapitest-h6gxbdhsdvehczcn.z01.azurefd.net/api/v2.0',
    __STORAGE_ACCOUNT__ : 'testswsautosignst',
    __INSTRUMENTATION_KEY__: 'b3d09cd0-5f51-492d-8a04-39cf9c45d19f',
    __CLIENT_API_SCOPES__: ['api://9e58da96-b95d-4e4f-97f5-093caf416773/user_impersonation']
};
const globals_pme = {
    /* =========================
     * Add build time variables here
     * These variables also need to added in ./global.d.ts file to be available in Typescript
     * ====================== */

    __IS_DEVELOPMENT__: process.env.NODE_ENV === 'development' || true,
    __APP_NAME__: 'AutoSign',
    __APP_VERSION__: '2.0',
    __CLIENT_ID__: '8ced2975-83d6-4d39-9724-a6b665f58506',
    __BASE_URL__: 'https://prod-sws-autosign-webui-westus3-azapp.azurewebsites.net/',
    __API_URL__ : 'https://autosignapiprod-f0eacqg5bnfvd3e4.z01.azurefd.net/api/v2.0',
    __STORAGE_ACCOUNT__ : 'prodswsautosignst',
    __INSTRUMENTATION_KEY__: 'f1860d34-2941-449d-b02e-701fa9104ff8',
    __CLIENT_API_SCOPES__: [`api://9e58da96-b95d-4e4f-97f5-093caf416773/user_impersonation`]
};
module.exports = {
    staticComponents,
    dynamicComponents,
    globals,
    globals_test,
    globals_pme_test,
    globals_pme
};
